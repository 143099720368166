import { CheckOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, List, Row, Skeleton, Tooltip, Typography } from 'antd';
import { Stack } from 'components/UI';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetReleasedProductQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { useGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { addBulkSearchedProduct, addSelectedProduct, removeSelectedProduct } from 'redux/slices/productSearchSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
const { Text, Title } = Typography;

interface Props {
  searchId: string;
}

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  }
};

export const BulkSearchItem: React.FC<Props> = ({ searchId }) => {
  /* ******************** Hooks ******************** */
  const { selectedProducts } = useAppSelector((state) => state.productSearch);
  const { juliaProductCatalogParams } = useAppSelector((state) => state);
  const { id: entityId } = useParams();

  const dispatch = useAppDispatch();

  const { data: releasedProduct, isLoading, isError, error } = useGetReleasedProductQuery(searchId);
  const { data: assignedCatalogItems, isLoading: checkAssignedIsLoading } = useGetProductCatalogItemsQuery(
    { customerId: entityId as string, params: { ...juliaProductCatalogParams, ...{ productNameContains: searchId, includeDeletedData: true } } },
    { skip: !entityId }
  );

  /* ******************** Functions / Variables ******************** */
  const isSelected = selectedProducts.some((item) => item.productNumber === releasedProduct?.productNumber);
  const typedError = !!error && (error as FetchBaseQueryError).data;

  const isAssigned = !!assignedCatalogItems?.data.find((catalogItem) => catalogItem.productNumber === searchId);
  const isRecovery = !!assignedCatalogItems?.data.find((catalogItem) => catalogItem.productNumber === searchId && catalogItem.isDeleted);

  const isProductMasterNotFound = typeof typedError === 'string' && typedError.includes('productMaster');

  const handleToggleSelectProduct = (): void => {
    if (releasedProduct) {
      if (isSelected) {
        dispatch(removeSelectedProduct(releasedProduct));
      } else {
        dispatch(addSelectedProduct(releasedProduct));
      }
    }
  };

  const extraJSX = !isAssigned ? (
    <Button loading={checkAssignedIsLoading} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
  ) : isRecovery ? (
    <Text type="warning">Recycled</Text>
  ) : (
    <Text type="success">Assigned</Text>
  );

  useEffect(() => {
    if (releasedProduct) {
      dispatch(addBulkSearchedProduct(releasedProduct));
    }
  }, [releasedProduct, dispatch]);

  if (isLoading) {
    return (
      <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
        <Card size="small" headStyle={{ paddingLeft: 8, paddingRight: 8 }} bodyStyle={{ padding: 8 }} style={styles.container}>
          <Skeleton active />
        </Card>
      </List.Item>
    );
  }

  if (!releasedProduct || isError) {
    return (
      <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
        <Card
          size="small"
          headStyle={{ paddingLeft: 8, paddingRight: 8 }}
          bodyStyle={{ padding: 8 }}
          style={{ ...styles.container, borderColor: 'red', opacity: 0.5 }}
          title={
            <Title level={5} style={styles.title}>
              {searchId}
            </Title>
          }>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text type="secondary" style={{ ...styles.description, color: 'red' }}>
                {isProductMasterNotFound ? typedError : 'Not Found'}
              </Text>
            </Col>
            <Col span={12}>
              <Stack flexDirection="column" gap={0} lineHeight={1}>
                <Text>Category</Text>
                <Text type="secondary">NA</Text>
              </Stack>
            </Col>
            <Col span={12}>
              <Stack flexDirection="column" gap={0} lineHeight={1} alignItems="flex-end">
                <Text>Manufacturer</Text>
                <Text type="secondary">NA</Text>
              </Stack>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  }

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 8 }}
        style={isAssigned ? { ...styles.container, opacity: 0.5 } : styles.container}
        title={
          <Tooltip title={releasedProduct.searchName}>
            <Title level={5} style={styles.title}>
              {releasedProduct.productNumber}
            </Title>
          </Tooltip>
        }
        extra={extraJSX}>
        <Row gutter={[8, 8]} align="top">
          <Col span={24}>
            <Text type="secondary" style={styles.description}>
              {releasedProduct.productDescription}
            </Text>
          </Col>
          <Col span={12}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              <Text>Category</Text>
              <Text type="secondary">{releasedProduct.productGroupId || 'NA'}</Text>
            </Stack>
          </Col>
          <Col span={12}>
            <Stack flexDirection="column" gap={5} lineHeight={1} alignItems="flex-end">
              <Text>Manufacturer</Text>
              <Text type="secondary">{releasedProduct.manufacturerName || 'NA'}</Text>
            </Stack>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
