import { List } from 'antd';
import { DivisionListItem } from 'components/atoms/DivisionListItem';
import { Business } from 'models/Business';
import React from 'react';

interface Props {
  divisions: Business['divisions'];
}

export const DivisionList = ({ divisions }: Props): JSX.Element => {
  return (
    <List
      grid={{ gutter: 4, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
      dataSource={divisions}
      renderItem={(division): JSX.Element => <DivisionListItem division={division} />}
      rowKey={(item): string => item.id}
      style={{ padding: 8 }}
    />
  );
};
