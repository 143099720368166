import { Button, message } from 'antd';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductCatalogItemsQuery, useLazyGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { useAppSelector } from 'redux/store';
import * as xlsx from 'xlsx';

export const ExportRulesButton = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { juliaProductCatalogParams } = useAppSelector((state) => state);

  const [getLazyProductCatalogItems, { isLoading }] = useLazyGetProductCatalogItemsQuery();

  const { data: productCatalogItemsResponse } = useGetProductCatalogItemsQuery({ customerId: entityId as string, params: juliaProductCatalogParams }, { skip: !entityId });

  const totalCount = productCatalogItemsResponse?.totalCount || 0;
  /* ******************** Functions ******************** */
  const handleExportRules = async (): Promise<void> => {
    const { data: results } = await getLazyProductCatalogItems({ customerId: entityId as string, params: { offset: 0, take: totalCount } }).unwrap();

    if (!results) {
      message.error('No data to export');

      return;
    }
    const worksheet = xlsx.utils.json_to_sheet(results);
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheet, 'default');
    const excelBuffer = await xlsx.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const uri = window.URL.createObjectURL(excelBlob);
    const link = document.createElement('a');

    link.setAttribute('href', uri);
    link.setAttribute('download', `product-catalog-items-${moment().format()}.xlsx`);

    link.click();
    URL.revokeObjectURL(uri);
  };

  return (
    <Button onClick={handleExportRules} loading={isLoading}>
      Export
    </Button>
  );
};
