import { message, Space } from 'antd';
import { AntButton } from 'components/UI/AntButton';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetReleasedProductsQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { setOffset } from 'redux/services/donaldGrump/releasedProductsParams';
import { useCheckProductCatalogItemAssignedQuery } from 'redux/services/julia/juliaApi';
import { setSelectedProducts } from 'redux/slices/productSearchSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const ProductSearchActions = (): JSX.Element => {
  const { id: customerId } = useParams();

  const {
    donaldGrumpReleasedProductsParams,
    productSearch: { selectedProducts }
  } = useAppSelector((state) => state);

  /* ******************** API Hooks ******************** */
  const { data } = useGetReleasedProductsQuery(donaldGrumpReleasedProductsParams, { skip: !donaldGrumpReleasedProductsParams.dataAreaId });
  const { data: assignedCatalogItems, isLoading: isAssignedLoading } = useCheckProductCatalogItemAssignedQuery(
    { customerId: customerId as string, productNumbers: data?.data.map((product) => product.productNumber) || [] },
    { skip: !data || !data.totalCount || !customerId }
  );
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  /* ******************** Functions / Variables ******************** */
  const totalSelectedProducts = selectedProducts.length;

  const handleSelectAllProducts = (): void => {
    if (data && !isAssignedLoading && assignedCatalogItems) {
      const nonAssignedProducts = data.data.filter((product) => !assignedCatalogItems.data.find((assignedProduct) => product.productNumber === assignedProduct.productNumber));

      dispatch(setSelectedProducts(nonAssignedProducts));
    } else {
      message.error('Nothing to select!');
    }
  };

  const handleDeselectAllProducts = (): void => {
    dispatch(setSelectedProducts([]));
  };

  const handleAssignSelectedProducts = (): void => {
    setSearchParams({ assignIds: selectedProducts.join(',') });
  };

  useEffect(
    () => (): void => {
      dispatch(setSelectedProducts([]));
      dispatch(setOffset(0));
    },
    [dispatch]
  );

  return (
    <Space>
      <AntButton onClick={handleDeselectAllProducts} type="ghost" danger disabled={!totalSelectedProducts}>
        Deselect All ({totalSelectedProducts})
      </AntButton>
      <AntButton onClick={handleSelectAllProducts} type="primary" disabled={!data}>
        Select All
      </AntButton>
      <AntButton onClick={handleAssignSelectedProducts} type="primary" success="true" disabled={!totalSelectedProducts}>
        Assign Selected ({totalSelectedProducts})
      </AntButton>
    </Space>
  );
};
