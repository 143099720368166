import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetOwnersQuery } from 'redux/services/animals/animalsApi';
import { setDefaultOverageOwnerContains } from 'redux/services/julia/productCatalogParams';
import { ReduxState, useAppDispatch, useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const OwnersFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { animalsOwnersParams } = useAppSelector((state) => state);
  const { data: ownersResponse, isLoading: isOwnersLoading } = useGetOwnersQuery(animalsOwnersParams);
  const dispatch = useAppDispatch();
  const { defaultOverageOwnerContains } = useSelector((state: ReduxState) => state.juliaProductCatalogParams);

  /* ******************** Variables ******************** */
  const ownersOptions: DefaultOptionType[] | undefined = ownersResponse?.data?.map((owner) => ({ value: owner.name, label: owner.name }));

  /* ******************** Functions ******************** */
  const handleChange = (value: string): void => {
    dispatch(setDefaultOverageOwnerContains(value === undefined ? undefined : value));
  };

  /********** cleanup ***********/
  useEffect(() => {
    return (): void => {
      dispatch(setDefaultOverageOwnerContains(undefined));
    };
  }, [dispatch]);

  return (
    <FancySelect
      placeholder="Overage Owner"
      options={ownersOptions}
      loading={isOwnersLoading}
      disabled={false}
      showSearch
      onChange={handleChange}
      value={defaultOverageOwnerContains === undefined ? undefined : defaultOverageOwnerContains}
      width={150}
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
