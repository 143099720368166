import { message, Space, SpinProps, Table, Tabs, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { ImportHeader } from 'components/organisms/ImportHeader';
import { RulesTable } from 'components/organisms/RulesTable';
import { Stack } from 'components/UI';
import { RateLimiter } from 'limiter';
import { ProductCatalogItem, ProductCatalogItemPayload } from 'models/ProductCatalog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useCreateProductCatalogItemMutation } from 'redux/services/julia/juliaApi';
import { setErrorItems, setImportItems, setSuccessItems } from 'redux/slices/importSlice';
import { useAppSelector } from 'redux/store';

const { TabPane } = Tabs;
const { Text } = Typography;

export const ImportRulesPage: React.FC = () => {
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');

  const { importTotal, importItems, errorItems } = useAppSelector((state) => state.import);

  const dispatch = useDispatch();

  const [activeTab, _setActiveTab] = useState('success');
  const [_successItems, _setSuccessItems] = useState<ProductCatalogItem[]>([]);
  const [_errorItems, _setErrorItems] = useState<{ productNumber: string; errors: string[] }[]>(errorItems);
  const isImporting = useRef(false);

  const [createCatalogItem] = useCreateProductCatalogItemMutation();

  /* ******************** Functions ******************** */
  const handleNavigation = (activeKey: string): void => {
    _setActiveTab(activeKey);
  };

  /* ****************** Variables ****************** */
  const importLoadingConfig: SpinProps = {
    indicator: (
      <Space style={{ width: 400, marginLeft: -200 }} size="small">
        <BlockLoader direction="loader loader--slideUp" />
        <Text>{`Importing ${_successItems.length + _errorItems.length} of ${importTotal}`}</Text>
      </Space>
    ),
    spinning: importTotal !== _successItems.length + _errorItems.length,
    wrapperClassName: importTotal !== _successItems.length + _errorItems.length ? 'list-loading' : ''
  };

  const importCatalogItems = useCallback(async (): Promise<void> => {
    if (entityId) {
      const limiter = new RateLimiter({ tokensPerInterval: 25, interval: 1500 });

      isImporting.current = true;

      for (const [i, sheetRow] of importItems.entries()) {
        const payload = { ...sheetRow, ...{ isActive: true, isOverridden: isBusinessEdit ? false : true } } as ProductCatalogItemPayload;

        await limiter.removeTokens(1);

        createCatalogItem({ customerId: entityId, payload })
          .unwrap()
          .then((product) => _setSuccessItems((prevState) => [...prevState, ...[product]]))
          .catch((error) => {
            if ('data' in error && error.status === 429) {
              console.log('Hit Rate Limit again');
              _setErrorItems((prevState) => [...prevState, ...[{ ...sheetRow, ...{ errors: [error.data] } }]]);
            } else if ('data' in error) {
              _setErrorItems((prevState) => [...prevState, ...[{ ...sheetRow, ...{ errors: [error.data] } }]]);
            } else {
              _setErrorItems((prevState) => [...prevState, ...[{ ...sheetRow, ...{ errors: [error.message] } }]]);
            }
          });
      }
    }
  }, [entityId, importItems, isBusinessEdit, createCatalogItem]);

  /* ****************** Use Effects ****************** */
  useEffect(() => {
    if (!isImporting.current) importCatalogItems();
  }, [importCatalogItems, importItems]);

  useEffect(() => {
    if (_successItems.length + _errorItems.length === importTotal) {
      dispatch(setSuccessItems(_successItems));
      message.info('Import finished processing');
    }
  }, [_errorItems.length, _successItems, _successItems.length, dispatch, importTotal]);

  useEffect(() => {
    return (): void => {
      dispatch(setSuccessItems([]));
      dispatch(setErrorItems([]));
      dispatch(setImportItems([]));
    };
  }, [dispatch]);

  /* ****************** Render ****************** */
  return (
    <Stack flexDirection="column">
      <ImportHeader errorItems={_errorItems} />
      <Tabs defaultActiveKey={activeTab} onChange={handleNavigation} type="card">
        <TabPane tab="Success" key="success">
          <RulesTable dataSource={_successItems} loading={importLoadingConfig} />
        </TabPane>
        <TabPane tab="Errors" key="errors">
          <Table
            size="small"
            dataSource={_errorItems}
            loading={importLoadingConfig}
            columns={[
              {
                dataIndex: 'productNumber',
                title: (
                  <Text ellipsis strong>
                    Product Number
                  </Text>
                ),
                key: 'productNumber',
                width: '30ch'
              },
              {
                dataIndex: 'errors',
                title: (
                  <Text ellipsis strong>
                    Error Message
                  </Text>
                ),
                key: 'errors',
                render: (value: string[]): JSX.Element => (
                  <Stack flexDirection="column">
                    {value.map((item, index) => (
                      <Text key={index}>{item}</Text>
                    ))}
                  </Stack>
                )
              }
            ]}
            rowKey="productNumber"
          />
        </TabPane>
      </Tabs>
    </Stack>
  );
};
