import { DownloadOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, message, Modal, Spin, SpinProps, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { productCatalogItemImportPayloadSchema, ProductCatalogItemPayload } from 'models/ProductCatalog';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetConditionsQuery, useGetDispositionsQuery, useGetOwnersQuery } from 'redux/services/animals/animalsApi';
import { useGetCatalogConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { addErrorItem, setImportItems, setImportTotal } from 'redux/slices/importSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { validatedHeaderRow } from 'utils/validation';
import * as xlsx from 'xlsx';
import { ValidationError } from 'yup';

const headers = [
  'productNumber',
  'defaultConfigType',
  'deploymentType',
  'isCommonStock',
  'defaultOverageOwner',
  'defaultOverageCondition',
  'defaultOverageDisposition',
  'salesCertReconPrice',
  'salesNewPrice',
  'salesUsedPrice'
];

export const ImportModal: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');
  const [isOpen, _setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading, _setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { animalsOwnersParams } = useAppSelector((state) => state);
  const { animalsConditionsParams } = useAppSelector((state) => state);
  const { animalsDispositionsParams } = useAppSelector((state) => state);

  const { data: ownersData, isLoading: isOwnersLoading, isError: isOwnersError, error: ownersError } = useGetOwnersQuery(animalsOwnersParams);
  const { data: conditionsData, isLoading: isConditionsLoading, isError: isConditionsError, error: conditionsError } = useGetConditionsQuery(animalsConditionsParams);
  const { data: dispositionsData, isLoading: isDispositionsLoading, isError: isDispostitionsError, error: dispositionsError } = useGetDispositionsQuery(animalsDispositionsParams);

  const { data: divisionCatalogConfig } = useGetCatalogConfigQuery({ customerId: entityId as string }, { skip: !entityId });

  /* ****************** Variables ****************** */
  const isBusinessOnly = divisionCatalogConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');
  const isOcdLoading = isOwnersLoading || isConditionsLoading || isDispositionsLoading;
  const isOcdError = isOwnersError || isConditionsError || isDispostitionsError;
  const importLoadingConfig: SpinProps = {
    indicator: (
      <div style={{ width: 50, marginLeft: -25 }}>
        <BlockLoader direction="loader loader--slideUp" />
      </div>
    ),
    spinning: isLoading,
    wrapperClassName: isLoading ? 'list-loading' : ''
  };

  /* ******************** Functions ******************** */
  const onOpen = (): void => _setIsOpen(true);
  const onClose = (): void => _setIsOpen(false);

  const handleImport = async (file: RcFile | Blob): Promise<void> => {
    if (!entityId) {
      message.error('No entity Id!');

      return;
    }

    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('You can only import files with the extension .xlsx');

      return;
    }

    _setIsLoading(true);
    const buffer = await file.arrayBuffer();
    const workbook = xlsx.read(buffer, { type: 'buffer' });

    for (const sheetName of workbook.SheetNames) {
      const sheetRows = xlsx.utils.sheet_to_json<ProductCatalogItemPayload>(workbook.Sheets[sheetName], { header: headers });

      const isHeaderRowValid = !!sheetRows.length && validatedHeaderRow(sheetRows[0]);

      if (isHeaderRowValid && isHeaderRowValid.length) {
        message.error(JSON.stringify(isHeaderRowValid), 8);
        _setIsLoading(false);

        return;
      }

      const itemsToCreate: ProductCatalogItemPayload[] = [];

      for (const sheetRow of sheetRows.slice(1)) {
        try {
          const data = await productCatalogItemImportPayloadSchema.validate(sheetRow);

          // // Check OCD exists
          if (!ownersData?.data.find((owner) => owner.name === data.defaultOverageOwner)) {
            throw new Error(`Owner "${data.defaultOverageOwner}" does not exist`);
          }
          if (!conditionsData?.data.find((condition) => condition.name === data.defaultOverageCondition)) {
            throw new Error(`Condition "${data.defaultOverageCondition}" does not exist`);
          }
          if (!dispositionsData?.data.find((disposition) => disposition.name === data.defaultOverageDisposition)) {
            throw new Error(`Disposition "${data.defaultOverageDisposition}" does not exist`);
          }

          itemsToCreate.push(sheetRow);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error instanceof ValidationError) {
            dispatch(addErrorItem({ ...sheetRow, ...{ errors: error.errors } }));
          } else if ('data' in error) {
            dispatch(addErrorItem({ ...sheetRow, ...{ errors: [error.data] } }));
          } else {
            dispatch(addErrorItem({ ...sheetRow, ...{ errors: [error.message] } }));
          }
        }
      }

      dispatch(setImportTotal(sheetRows.slice(1).length));
      dispatch(setImportItems(itemsToCreate));
      _setIsLoading(false);
      navigate(`/${isBusinessEdit ? 'business' : 'division'}/${entityId}/import`);
    }
  };

  return (
    <>
      <ButtonGroup>
        <Tooltip title={isOcdLoading ? 'Retreiving all OCD values...' : isOcdError && ownersError ? ((ownersError as FetchBaseQueryError).data as string) : ''}>
          <Button onClick={onOpen} disabled={isBusinessOnly || isOcdError} loading={isOcdLoading}>
            Import
          </Button>
        </Tooltip>
        <Tooltip title="Download import template">
          <Button download href="/import-product-catalog-template.xlsx">
            <DownloadOutlined />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Modal visible={isOpen} onCancel={onClose} title="Import" footer={[]}>
        <Spin spinning={isLoading} indicator={importLoadingConfig.indicator} tip={`Preparing Import`}>
          <Dragger
            showUploadList={false}
            customRequest={({ file }): void => {
              if (typeof file !== 'string') handleImport(file);
            }}>
            Upload (xlsx)
          </Dragger>
        </Spin>
      </Modal>
    </>
  );
};
