import { ChangeEvent } from 'react';

export const validatedHeaderRow = (row: any): string[] => {
  const errors: string[] = [];

  for (const [key, value] of Object.entries(row)) {
    if (key !== value) {
      errors.push(`${key} column doesn't exist or is misspelled!`);
    }
  }

  return errors;
};

export const getOnlyNumbers =
  (callback: (value: any) => void) =>
  (event: ChangeEvent<HTMLInputElement>): void => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === '' || re.test(event.target.value)) {
      console.log('test');
      callback(event.target.value);
    }
  };
