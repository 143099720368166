import React, { useEffect } from 'react';
import { setDefaultConfigTypeContains } from 'redux/services/julia/productCatalogParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const DefaultConfigTypeFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setDefaultConfigTypeContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setDefaultConfigTypeContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Config Type" onChange={handleChange} />;
};
