import { Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import { FC } from 'react';
import { useGetDispositionsQuery } from 'redux/services/animals/animalsApi';
import { useAppSelector } from 'redux/store';

interface Props {
  hideLabel?: boolean;
  isInheritanceBusiness: boolean;
}

export const DispositionsInput: FC<Props> = ({ hideLabel, isInheritanceBusiness }) => {
  /* ******************** Hooks ******************** */
  const { animalsDispositionsParams } = useAppSelector((state) => state);
  const { data: dispositionsResponse, isLoading: isDispositionsLoading } = useGetDispositionsQuery(animalsDispositionsParams);

  /* ******************** Variables ******************** */
  const dispositionOptions: DefaultOptionType[] | undefined = dispositionsResponse?.data?.map((disposition) => ({ value: disposition.name, label: disposition.name }));

  return (
    <SelectInput
      fieldName="defaultOverageDisposition"
      label={
        hideLabel ? undefined : (
          <Tooltip title="This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.">
            {AssignedProductsFormLabels.defaultOverageDisposition}
          </Tooltip>
        )
      }
      options={dispositionOptions}
      loading={isDispositionsLoading}
      disabled={isInheritanceBusiness}
      showSearch
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
