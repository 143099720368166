import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Radio, SelectProps } from 'antd';
import { useField, useFormikContext } from 'formik';
import { CreateUserPayload } from 'models/Users';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const FormRadio = ({ fieldName, label }: Props): JSX.Element => {
  const [, { touched, error }, { setValue }] = useField<string>(fieldName);
  const { pathname } = useLocation();
  const { values } = useFormikContext();
  const userId = pathname.includes('/users/') && pathname.split('/')[2];
  const onCreate = pathname.includes('create-user');
  const formValues = values as CreateUserPayload;
  const { data: userData } = useGetUserQuery(userId || skipToken);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Radio.Group
        defaultValue={onCreate ? formValues.isMdsiEmployee : userData?.user_metadata.is_mdsi_employee}
        onChange={(value): void => setValue(value.target.value)}
        style={{ borderRadius: 5, marginTop: 16 }}
        buttonStyle="solid">
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
