import { Form, InputNumber, InputNumberProps } from 'antd';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  label?: string;
}

export const CurrencyInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<number>(fieldName);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <InputNumber
        {...rest}
        formatter={(value: string | number | undefined): string => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value: string | undefined): string => value!.replace(/\$\s?|(,*)/g, '')}
        value={value || undefined}
        onChange={(value): void => setValue(Number(value))}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5, width: '100%' }}
        controls={false}
      />
    </Form.Item>
  );
};
