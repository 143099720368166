import { List } from 'antd';
import { AssignProductsModal } from 'components/molecules/AssignProductsModal';
import { BulkSearchItem } from 'components/molecules/BulkSearchItem';
import { BulkSearchControls } from 'components/organisms/BulkSearchControls';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const BulkSearchPage: React.FC = () => {
  /* ******************** Hooks ******************** */
  const [searchParams] = useSearchParams();

  /* ******************** Functions / Variables ******************** */
  const searchIdsParam = searchParams.get('searchIds');
  const searchIds = searchIdsParam ? searchIdsParam.split(',') : [];

  /* ******************** Renderer ******************** */
  return (
    <>
      <BulkSearchControls />
      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 }}
        dataSource={searchIds}
        renderItem={(item): JSX.Element => <BulkSearchItem searchId={item} />}
        rowKey={(item): string => item}
        style={{ overflowX: 'hidden' }}
      />
      <AssignProductsModal />
    </>
  );
};
