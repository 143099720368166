import { Button, Input, Modal } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const BulkSearchModal = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [isOpen, _setIsOpen] = useState(false);
  const [value, _setValue] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');

  /* ******************** Functions ******************** */
  const handleShow = (): void => _setIsOpen(true);
  const handleClose = (): void => _setIsOpen(false);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    _setValue(event.target.value);
  };

  const handleSubmit = (): void => {
    const searchIds = value.split('\n');

    navigate(`/${isBusinessEdit ? 'business' : 'division'}/${id}/bulk-search`.concat(`?searchIds=${searchIds}`));
    handleClose();
  };

  return (
    <>
      <Button onClick={handleShow} type="primary">
        Bulk Search
      </Button>
      <Modal title="Bulk Product Search" visible={isOpen} onCancel={handleClose} onOk={handleSubmit}>
        <Input.TextArea value={value} onChange={handleValueChange} autoSize={{ minRows: 15 }} />
      </Modal>
    </>
  );
};
