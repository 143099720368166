import { About } from 'pages/About';
import { AssignedRule } from 'pages/AssignedRule';
import { AssignedRulesPage } from 'pages/AssignedRules';
import { BulkSearchPage } from 'pages/BulkSearch';
import { EditLayout } from 'pages/EditLayout';
import { EditProfilePage } from 'pages/EditProfile';
import { Forbidden } from 'pages/Forbidden';
import { HomePage } from 'pages/Home';
import { ImportRulesPage } from 'pages/ImportRules';
import { ProductSearchPage } from 'pages/ProductSearch';
import { RecoverRulesPage } from 'pages/RecoverRules';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="/business/:id" element={<EditLayout />}>
        <Route index element={<AssignedRulesPage />} />
        <Route path="/business/:id/rules/:productId" element={<AssignedRule />} />
        <Route path="/business/:id/recover" element={<RecoverRulesPage />} />
        <Route path="/business/:id/search" element={<ProductSearchPage />} />
        <Route path="/business/:id/bulk-search" element={<BulkSearchPage />} />
        <Route path="/business/:id/import" element={<ImportRulesPage />} />
      </Route>
      <Route path="/division/:id" element={<EditLayout />}>
        <Route index element={<AssignedRulesPage />} />
        <Route path="/division/:id/rules/:productId" element={<AssignedRule />} />
        <Route path="/division/:id/recover" element={<RecoverRulesPage />} />
        <Route path="/division/:id/search" element={<ProductSearchPage />} />
        <Route path="/division/:id/bulk-search" element={<BulkSearchPage />} />
        <Route path="/division/:id/import" element={<ImportRulesPage />} />
      </Route>
    </Routes>
  );
};
