import { Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FancyInput, FancySelect } from 'components/atoms/Inputs';
import { RefetchButton } from 'components/atoms/RefetchButton';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { BusinessInheritanceIcon, DivisionInheritanceIcon, OverrideInheritanceIcons } from 'components/UI/InheritanceIcons';
import React, { ChangeEvent, useEffect } from 'react';
import { setCustomerSearch, setInheritanceTypeFilter } from 'redux/slices/businessListSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const HomeControls: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { inheritanceTypeFilter } = useAppSelector((state) => state.businessList);
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleBusinessTypeFilterChange = (type: ('Division' | 'Business' | 'BusinessWithDivisionOverride')[]): void => {
    dispatch(setInheritanceTypeFilter(type));
  };

  const filterOptions: DefaultOptionType[] = [
    {
      label: (
        <Stack alignItems="center" height={20}>
          <BusinessInheritanceIcon />
          <Typography>Business</Typography>
        </Stack>
      ),
      value: 'Business'
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <DivisionInheritanceIcon />
          <Typography>Division</Typography>
        </Stack>
      ),
      value: 'Division'
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <OverrideInheritanceIcons />
          <Typography>Business Override</Typography>
        </Stack>
      ),
      value: 'BusinessWithDivisionOverride'
    }
  ];

  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerSearch(undefined));
      dispatch(setInheritanceTypeFilter(['Business', 'Division', 'BusinessWithDivisionOverride']));
    };
  }, [dispatch]);

  /* ******************** Renderer ******************** */
  return (
    <Space align="center">
      <AntPageTitle text="Customers" />
      <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
      <FancySelect
        placeholder="Business Type"
        options={filterOptions}
        value={inheritanceTypeFilter}
        onChange={handleBusinessTypeFilterChange}
        dropdownMatchSelectWidth={false}
        mode="multiple"
        defaultValue={inheritanceTypeFilter}
      />
      <RefetchButton />
    </Space>
  );
};
