import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ExportRulesButton } from 'components/atoms/ExportRulesButton';
import { AssignedRulesActions } from 'components/molecules/AssignedRulesActions';
import { ImportModal } from 'components/molecules/ImportModal';
import { RecoverRulesActions } from 'components/molecules/RecoverRulesActions';
import { RulesFilters } from 'components/molecules/RulesFilters';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetParams } from 'redux/services/julia/productCatalogParams';
import { RulesNavigation } from './RulesNavigation';

export const AssignedRulesControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  /* ****************** API hooks ****************** */

  /* ******************** Variables /Functions ******************** */
  const isRecoverPage = location.pathname.includes('recover');

  const handleNavigateToRecover = (): void => {
    navigate('recover');
  };

  const handleNavigateToRules = (): void => {
    dispatch(resetParams());
    navigate(location.pathname.replace('/recover', ''));
  };

  return (
    <Stack flexDirection="column" gap={16}>
      <Stack justifyContent="space-between" alignItems="flex-end">
        <Stack flexDirection="column">
          <AntPageTitle text={isRecoverPage ? 'Recycle Bin' : 'Product Catalog Items'} />
          <RulesFilters />
        </Stack>
        <Stack>
          {isRecoverPage && (
            <Button onClick={handleNavigateToRules} type="primary">
              Back to Catalog Items
            </Button>
          )}

          {!isRecoverPage && <Button onClick={handleNavigateToRecover} icon={<DeleteOutlined />} />}
          {!isRecoverPage && <ImportModal />}
          {!isRecoverPage && <ExportRulesButton />}
        </Stack>
      </Stack>
      <Stack justifyContent="space-between">
        <RulesNavigation />
        {isRecoverPage ? <RecoverRulesActions /> : <AssignedRulesActions />}
      </Stack>
    </Stack>
  );
};
