import { Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import { FC } from 'react';
import { useGetOwnersQuery } from 'redux/services/animals/animalsApi';
import { useAppSelector } from 'redux/store';

interface Props {
  hideLabel?: boolean;
  isInheritanceBusiness: boolean;
}

export const OwnersInput: FC<Props> = ({ hideLabel, isInheritanceBusiness }) => {
  /* ******************** Hooks ******************** */
  const { animalsOwnersParams } = useAppSelector((state) => state);
  const { data: ownersResponse, isLoading: isOwnersLoading } = useGetOwnersQuery(animalsOwnersParams);

  /* ******************** Variables ******************** */
  const ownersOptions: DefaultOptionType[] | undefined = ownersResponse?.data?.map((owner) => ({ value: owner.name, label: owner.name }));

  return (
    <SelectInput
      fieldName="defaultOverageOwner"
      label={
        hideLabel ? undefined : (
          <Tooltip title="This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.">{AssignedProductsFormLabels.defaultOverageOwner}</Tooltip>
        )
      }
      options={ownersOptions}
      loading={isOwnersLoading}
      disabled={isInheritanceBusiness}
      showSearch
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
