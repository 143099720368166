import { message, Modal, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FormikProvider, useFormik } from 'formik';
import { AssignRuleForm } from 'models/ProductCatalog';
import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useCreateProductCatalogItemMutation } from 'redux/services/julia/juliaApi';
import { useGetCatalogConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setSelectedProducts } from 'redux/slices/productSearchSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { AssignRulesFormModal } from './AssignRulesFormModal';

export const AssignProductsModal = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const [creationCount, setCreationCount] = useState(1);

  const { selectedProducts } = useAppSelector((state) => state.productSearch);

  const [createCatalogItem] = useCreateProductCatalogItemMutation();

  const { data: catalogConfigData } = useGetCatalogConfigQuery({ customerId: entityId as string }, { skip: !entityId || isBusinessEdit });

  /* ******************** Functions / Variables ******************** */
  const assignIdsParamValue = searchParams.get('assignIds');
  const assignIds = assignIdsParamValue ? assignIdsParamValue.split(',') : [];
  const isOpen = Boolean(assignIdsParamValue);

  const handleClose = (): void => {
    searchParams.delete('assignIds');
    setSearchParams(searchParams);
  };

  const handleAssignProducts = async (values: AssignRuleForm): Promise<void> => {
    if (!entityId) {
      message.error('Entity Id is missing!');

      return;
    }

    for (const selectedProduct of selectedProducts) {
      try {
        await createCatalogItem({
          customerId: entityId,
          payload: {
            ...values,
            productName: selectedProduct.productNumber,
            productNumber: selectedProduct.productNumber,
            category: selectedProduct.productGroupId,
            manufacturer: selectedProduct.manufacturerName
          }
        }).unwrap();
        message.success(`Product catalog items were successfully created!`);
        setCreationCount((prev) => prev + 1);
      } catch {
        message.error('Product catalog item failed to be created!');
        break;
      }
    }

    setCreationCount(1);
    dispatch(setSelectedProducts([]));
    handleClose();
  };

  const formik = useFormik<AssignRuleForm>({
    // validationSchema: assignRuleFormSchema,
    initialValues: {
      defaultConfigType: '',
      defaultOverageOwner: '',
      deploymentType: '',
      isCommonStock: false,
      defaultOverageCondition: '',
      defaultOverageDisposition: '',
      salesCertReconPrice: 0,
      salesNewPrice: 0,
      salesUsedPrice: 0,
      isOverridden: isBusinessEdit ? false : catalogConfigData?.dataInheritanceLevel !== 'Business' ? true : false,
      isActive: true
    },
    onSubmit: handleAssignProducts
  });

  /* ******************** Renderer ******************** */
  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      title={<Typography.Title level={4} style={{ marginBottom: 0 }}>{`Assign Selected Product (${assignIds.length})`}</Typography.Title>}
      visible={isOpen}
      closable={!formik.isSubmitting}
      afterClose={(): void => formik.resetForm()}
      cancelButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      okButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      onOk={(): void => formik.handleSubmit()}
      onCancel={!formik.isSubmitting ? handleClose : undefined}
      okText="Assign"
      confirmLoading={formik.isSubmitting}
      width={600}>
      <FormikProvider value={formik}>
        <Spin
          spinning={formik.isSubmitting}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-20%' }}>
              <BlockLoader direction="loader loader--slideUp" style={{ marginBottom: 30 }} />
              <Typography.Text>{`Creating Catalog Item ${creationCount} of ${selectedProducts.length}...`}</Typography.Text>
            </Space>
          }>
          <AssignRulesFormModal isInheritanceBusiness={false} />
        </Spin>
      </FormikProvider>
    </Modal>
  );
};
