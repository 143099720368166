import React, { useEffect } from 'react';
import { setSearchName } from 'redux/services/donaldGrump/releasedProductsParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const ProductNameFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setSearchName(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setSearchName(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Product Name" onChange={handleChange} />;
};
