import * as yup from 'yup';
import { AssignRuleForm } from './ProductCatalog';

export type AssignedProductsUpdateForm = Partial<AssignRuleForm>;

export const assignedProductsUpdateFormSchema: yup.SchemaOf<AssignedProductsUpdateForm> = yup.object({
  defaultConfigType: yup.string(),
  defaultOverageOwner: yup.string(),
  deploymentType: yup.string(),
  isCommonStock: yup.boolean(),
  defaultOverageCondition: yup.string(),
  defaultOverageDisposition: yup.string(),
  salesCertReconPrice: yup.number(),
  salesNewPrice: yup.number(),
  salesUsedPrice: yup.number(),
  isOverridden: yup.boolean(),
  isActive: yup.boolean(),
  customerXRef: yup.object()
});

export const AssignedProductsFormLabels: Record<keyof AssignedProductsUpdateForm, string> = {
  defaultConfigType: 'Default Config Type',
  defaultOverageOwner: 'Default Overage Owner',
  deploymentType: 'Deployment Type',
  isCommonStock: 'Is Common Stock?',
  defaultOverageCondition: 'Default Overage Condition',
  defaultOverageDisposition: 'Default Overage Disposition',
  salesCertReconPrice: 'Sales Price: Certified Reconditioned',
  salesNewPrice: 'Sales Price: New',
  salesUsedPrice: 'Sales Price: Used',
  isOverridden: 'Is Overridden',
  isActive: 'Is Active',
  customerXRef: 'Customer XRef'
} as const;
