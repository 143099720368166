import { Button, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDeleteProductCatalogItemMutation } from 'redux/services/julia/juliaApi';
import { setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppSelector } from 'redux/store';

export const AssignedRulesActions = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    assignedRules: { selectedRules }
  } = useAppSelector((state) => state);

  const [deleteCatalogItem, { isLoading }] = useDeleteProductCatalogItemMutation();

  /* ******************** Functions / Variables ******************** */
  const totalSelectedRules = selectedRules.length;

  const handleUpdateSelectedRoles = (): void => {
    searchParams.set('productIds', selectedRules.join(','));
    setSearchParams(searchParams);
  };

  const handleDeleteItems = async (): Promise<void> => {
    if (!entityId) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const productId of selectedRules) {
      try {
        deleteCatalogItem({ customerId: entityId, productId }).unwrap();
      } catch (error) {
        message.error(`Failed to delete product catalog item ${productId}`);
      }
    }

    message.success(`Deleted product catalog item(s)!`);
    dispatch(setSelectedRules([]));
  };

  useEffect(() => (): any => dispatch(setSelectedRules([])), []);

  return (
    <Space>
      <Button onClick={handleUpdateSelectedRoles} type="primary" disabled={!totalSelectedRules} loading={isLoading}>
        Update Selected ({totalSelectedRules})
      </Button>
      <Button onClick={handleDeleteItems} danger disabled={!totalSelectedRules} loading={isLoading}>
        Delete Selected ({totalSelectedRules})
      </Button>
    </Space>
  );
};
