import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { AssignedRulesEditItem } from 'components/atoms/AssignedRulesEditItem';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { ConditionsInput } from 'components/atoms/FormInputs/ConditionsInput';
import { DeploymentTypeInput } from 'components/atoms/FormInputs/DeploymentTypeInput';
import { DispositionsInput } from 'components/atoms/FormInputs/DispositionsInput';
import { OwnersInput } from 'components/atoms/FormInputs/OwnersInput';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { CurrencyInput } from 'components/UI/FormItems/CurrencyInput';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { AssignedProductsUpdateForm, assignedProductsUpdateFormSchema } from 'models/UpdateProductCatalog';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetProductCatalogItemQuery, useUpdateProductCatalogItemMutation } from 'redux/services/julia/juliaApi';
import { removeAllSelectedRules } from 'redux/slices/assignedRulesSlice';

export interface InputOption {
  value: keyof AssignedProductsUpdateForm;
  render: JSX.Element;
}

const selectOptions: Record<keyof AssignedProductsUpdateForm, InputOption> = {
  defaultConfigType: { value: 'defaultConfigType', render: <TextInput fieldName="defaultConfigType" /> },
  defaultOverageOwner: { value: 'defaultOverageOwner', render: <OwnersInput hideLabel isInheritanceBusiness={false} /> },
  deploymentType: {
    value: 'deploymentType',
    render: <DeploymentTypeInput hideLabel isInheritanceBusiness={false} />
  },
  isCommonStock: { value: 'isCommonStock', render: <SwitchInput fieldName="isCommonStock" isInheritanceBusiness={false} /> },
  defaultOverageCondition: {
    value: 'defaultOverageCondition',
    render: <ConditionsInput hideLabel isInheritanceBusiness={false} />
  },
  defaultOverageDisposition: {
    value: 'defaultOverageDisposition',
    render: <DispositionsInput hideLabel isInheritanceBusiness={false} />
  },
  salesCertReconPrice: {
    value: 'salesCertReconPrice',
    render: <CurrencyInput fieldName="salesCertReconPrice" />
  },
  salesNewPrice: { value: 'salesNewPrice', render: <CurrencyInput fieldName="salesNewPrice" /> },
  salesUsedPrice: { value: 'salesUsedPrice', render: <CurrencyInput fieldName="salesUsedPrice" /> },

  isOverridden: {
    value: 'isOverridden',
    render: <SwitchInput fieldName="isOverridden" isInheritanceBusiness={false} />
  },
  isActive: {
    value: 'isActive',
    render: <SwitchInput fieldName="isActive" isInheritanceBusiness={false} />
  },
  customerXRef: {
    value: 'customerXRef',
    render: <></>
  }
};
const selectOptionsNoOverride: any = {
  defaultConfigType: { value: 'defaultConfigType', render: <TextInput fieldName="defaultConfigType" /> },
  defaultOverageOwner: { value: 'defaultOverageOwner', render: <OwnersInput hideLabel isInheritanceBusiness={false} /> },
  deploymentType: {
    value: 'deploymentType',
    render: <DeploymentTypeInput hideLabel isInheritanceBusiness={false} />
  },
  isCommonStock: { value: 'isCommonStock', render: <SwitchInput fieldName="isCommonStock" isInheritanceBusiness={false} /> },
  defaultOverageCondition: {
    value: 'defaultOverageCondition',
    render: <ConditionsInput hideLabel isInheritanceBusiness={false} />
  },
  defaultOverageDisposition: {
    value: 'defaultOverageDisposition',
    render: <DispositionsInput hideLabel isInheritanceBusiness={false} />
  },
  salesCertReconPrice: {
    value: 'salesCertReconPrice',
    render: <CurrencyInput fieldName="salesCertReconPrice" />
  },
  salesNewPrice: { value: 'salesNewPrice', render: <CurrencyInput fieldName="salesNewPrice" /> },
  salesUsedPrice: { value: 'salesUsedPrice', render: <CurrencyInput fieldName="salesUsedPrice" /> },
  isWebOrderDimPermissionsOverageDefault: {
    value: 'isWebOrderDimPermissionsOverageDefault',
    render: <SwitchInput fieldName="isWebOrderDimPermissionsOverageDefault" isInheritanceBusiness={false} />
  },
  isActive: {
    value: 'isActive',
    render: <SwitchInput fieldName="isActive" isInheritanceBusiness={false} />
  }
};

export const AssignedRulesEditModal = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessView = pathname.includes('business');

  const [searchParams, setSearchParams] = useSearchParams();
  const [updatedCount, setUpdatedCount] = useState(1);
  const [editFields, _setEditFields] = useState<(keyof AssignedProductsUpdateForm)[]>(['defaultConfigType']);

  const [updateCatalogItem] = useUpdateProductCatalogItemMutation();

  const [getCatalogItem] = useLazyGetProductCatalogItemQuery();

  /* ******************** Functions / Variables ******************** */
  const productIdsParamValue = searchParams.get('productIds');
  const productIds = productIdsParamValue ? productIdsParamValue.split(',') : [];
  const isOpen = Boolean(productIdsParamValue);
  const loadingUpdatingCatalog = useRef(false);

  const handleClose = (): void => {
    searchParams.delete('productIds');
    setSearchParams(searchParams);
    _setEditFields(['defaultConfigType']);
  };

  const handleUpdateProducts = async (values: AssignedProductsUpdateForm, { resetForm }: FormikHelpers<AssignedProductsUpdateForm>): Promise<void> => {
    if (!entityId) {
      message.error('No entity id!');

      return;
    }
    loadingUpdatingCatalog.current = true;
    let hadErrorOnUpdate = false;

    for (const productId of productIds) {
      try {
        const data = await getCatalogItem({ customerId: entityId, productId }).unwrap();

        await updateCatalogItem({
          customerId: entityId,
          productId,
          payload: { ...data, ...{ ...values, customerXRef: { customerStandardCost: values.customerXRef?.customerStandardCost as number } } }
        }).unwrap();
        setUpdatedCount((prev) => prev + 1);
      } catch (error) {
        message.error(`Unable to update product catalog item ${productId}`);
        hadErrorOnUpdate = true;
      }
    }

    if (!hadErrorOnUpdate) message.success(`Product catalog items were successfully updated!`);

    setUpdatedCount(1);
    loadingUpdatingCatalog.current = false;
    resetForm();
    _setEditFields(['defaultConfigType']);
    dispatch(removeAllSelectedRules());
    handleClose();
  };

  const formik = useFormik<AssignedProductsUpdateForm>({
    validationSchema: assignedProductsUpdateFormSchema,
    initialValues: {},
    onSubmit: handleUpdateProducts
  });

  const availableSelectOptions = Object.keys(selectOptions).filter((item) => !editFields.includes(item as keyof AssignedProductsUpdateForm)) as (keyof AssignedProductsUpdateForm)[];
  const availableSelectOptionsNoOverride = Object.keys(selectOptionsNoOverride).filter(
    (item) => !editFields.includes(item as keyof AssignedProductsUpdateForm)
  ) as (keyof AssignedProductsUpdateForm)[];

  const isAddFieldDisabled = availableSelectOptions.length;

  const handleAddField = (): void => {
    _setEditFields((prevState) => [...prevState, availableSelectOptions[0]]);
  };

  const handleSelectFields = (newValue: keyof AssignedProductsUpdateForm, oldValue: keyof AssignedProductsUpdateForm): void => {
    _setEditFields((prevState) => {
      const newState = [...prevState];
      const index = newState.indexOf(oldValue);

      newState[index] = newValue;

      return newState;
    });
  };

  const handleRemoveField = (fieldName: string): void => {
    formik.setFieldValue(fieldName, undefined);
    _setEditFields((prevState) => prevState.filter((item) => item !== fieldName));
  };

  const handleChangedField = (fieldName: string): void => {
    formik.setFieldValue(fieldName, undefined);
  };

  // *** Auto Sets Formik Toggle Fields to False when Selecting One of Them *** //
  const handleToggleFields = (fieldName: string): void => {
    if (fieldName === 'isCommonStock' || fieldName === 'isWebOrderDimPermissionsOverageDefault' || fieldName === 'isActive') {
      formik.setFieldValue(fieldName, false);
    }
  };

  /* ******************** Renderer ******************** */
  return (
    <Modal
      title={`Edit Product Catalogs (${productIds.length})`}
      visible={isOpen}
      closable={!formik.isSubmitting}
      onOk={(): void => formik.handleSubmit()}
      afterClose={(): void => formik.resetForm()}
      cancelButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      okButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      onCancel={!formik.isSubmitting ? handleClose : undefined}
      confirmLoading={loadingUpdatingCatalog.current}
      width={730}
      okText="Update">
      <FormikProvider value={formik}>
        <Spin
          spinning={formik.isSubmitting}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-6%' }}>
              <BlockLoader direction="loader loader--slideUp" style={{ marginBottom: 20 }} />
              <Typography.Text>{`Updating Catalog Item ${updatedCount} of ${productIds.length}...`}</Typography.Text>
            </Space>
          }>
          <Form layout="vertical">
            <Row gutter={[8, 16]} align="middle" justify="center">
              {editFields.map((item) => (
                <AssignedRulesEditItem
                  option={selectOptions[item]}
                  availableSelectOptions={isBusinessView ? availableSelectOptionsNoOverride : availableSelectOptions}
                  selectField={handleSelectFields}
                  removeField={handleRemoveField}
                  changeField={handleChangedField}
                  toggleFields={handleToggleFields}
                  key={item}
                />
              ))}
              <Col span={24}>
                <Button onClick={handleAddField} disabled={!isAddFieldDisabled} type="dashed" block icon={<PlusOutlined />}>
                  Add New Field
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </FormikProvider>
    </Modal>
  );
};
