import * as yup from 'yup';
export interface ProductCatalogItemsParams {
  alternateItemIdContains?: string;
  offset: number;
  take: number;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
  productNumberContains?: string;
  productNumbers?: string;
  isCommonStock?: boolean;
  isWebOrderDimPermissionsOverageDefault?: boolean;
  isOverridden?: boolean;
  defaultOverageConditionContains?: string;
  defaultOverageDispositionContains?: string;
  defaultOverageOwnerContains?: string;
  defaultConfigTypeContains?: string;
  deploymentTypeContains?: string;
  // For Url Purposes
}

export interface ProductCatalogItemPayload {
  category: string;
  defaultConfigType: string;
  defaultOverageOwner: string;
  deploymentType: string;
  isCommonStock: boolean;
  manufacturer?: string;
  defaultOverageCondition: string;
  defaultOverageDisposition: string;
  productName: string;
  productNumber: string;
  salesCertReconPrice: number;
  salesNewPrice: number;
  salesUsedPrice: number;
  isOverridden: boolean;
  isActive: boolean;
  customerXRef?: {
    alternateItemId?: string;
    alternateItemIdLabel?: string;
    customerStandardCost?: number | string;
    modifiedDateTime?: string;
  };
}
export interface AssignRulePayload {
  defaultConfigType?: string;
  defaultOverageOwner?: string;
  deploymentType: string;
  isCommonStock?: boolean;
  defaultOverageCondition?: string;
  defaultOverageDisposition?: string;
  salesCertReconPrice?: number;
  salesNewPrice?: number;
  salesUsedPrice?: number;
  isOverridden?: boolean;
  isActive?: boolean;
  customerXRef?: {
    alternateItemId?: string;
    alternateItemIdLabel?: string;
    customerStandardCost?: number | string;
    modifiedDateTime?: string;
  };
}

export enum ProductCatalogItemLabels {
  category = 'Category',
  defaultConfigType = 'Config Type',
  defaultOverageOwner = 'Default Overage Owner',
  deploymentType = 'Deployment Type',
  isCommonStock = 'Is Common Stock',
  manufacturer = 'Manufacturer',
  defaultOverageCondition = 'Default Overage Condition',
  defaultOverageDisposition = 'Default Overage Disposition',
  productName = 'Product Name',
  productNumber = 'Product Number',
  salesCertReconPrice = 'Certification Sales Price',
  salesNewPrice = 'New Sales Price',
  salesUsedPrice = 'Used Sales Price',
  isWebOrderDimPermissionsOverageDefault = 'Weborder permissions',
  isOverridden = 'Is Overridden'
}

export enum DeploymentTypes {
  AllOrders = 'AllOrders',
  StandardOrder = 'StandardOrder',
  SparingOrder = 'SparingOrder'
}

export const DeploymentTypesLabels = {
  AllOrders: 'All Orders',
  StandardOrder: 'Standard Order',
  SparingOrder: 'Sparing Order'
} as const;

export const productCatalogItemPayloadSchema: yup.SchemaOf<ProductCatalogItemPayload> = yup.object({
  defaultConfigType: yup.string().label(ProductCatalogItemLabels.defaultConfigType).required(),
  defaultOverageOwner: yup.string().label(ProductCatalogItemLabels.defaultOverageOwner).required(),
  deploymentType: yup.string().label(ProductCatalogItemLabels.deploymentType).required(),
  isCommonStock: yup.boolean().label(ProductCatalogItemLabels.isCommonStock).required(),
  defaultOverageCondition: yup.string().label(ProductCatalogItemLabels.defaultOverageCondition).required(),
  defaultOverageDisposition: yup.string().label(ProductCatalogItemLabels.defaultOverageDisposition).required(),
  productNumber: yup.string().label(ProductCatalogItemLabels.productNumber).required(),
  productName: yup.string().label(ProductCatalogItemLabels.productName).required(),
  category: yup.string().label(ProductCatalogItemLabels.category).required(),
  manufacturer: yup.string().label(ProductCatalogItemLabels.manufacturer),
  salesCertReconPrice: yup.number().label(ProductCatalogItemLabels.salesCertReconPrice).required(),
  salesNewPrice: yup.number().label(ProductCatalogItemLabels.salesNewPrice).required(),
  salesUsedPrice: yup.number().label(ProductCatalogItemLabels.salesUsedPrice).required(),
  isWebOrderDimPermissionsOverageDefault: yup.boolean().label(ProductCatalogItemLabels.isWebOrderDimPermissionsOverageDefault),
  isOverridden: yup.boolean().label(ProductCatalogItemLabels.isOverridden).required(),
  isActive: yup.boolean().required(),
  customerXRef: yup.object()
});

export const productCatalogItemImportPayloadSchema: yup.SchemaOf<Omit<ProductCatalogItemPayload, 'isActive' | 'isOverridden' | 'productName' | 'manufacturer' | 'category'>> = yup.object({
  defaultConfigType: yup.string().label(ProductCatalogItemLabels.defaultConfigType).required(),
  defaultOverageOwner: yup.string().label(ProductCatalogItemLabels.defaultOverageOwner).required(),
  deploymentType: yup.string().label(ProductCatalogItemLabels.deploymentType).required(),
  isCommonStock: yup.boolean().label(ProductCatalogItemLabels.isCommonStock).required(),
  defaultOverageCondition: yup.string().label(ProductCatalogItemLabels.defaultOverageCondition).required(),
  defaultOverageDisposition: yup.string().label(ProductCatalogItemLabels.defaultOverageDisposition).required(),
  productNumber: yup.string().label(ProductCatalogItemLabels.productNumber).required(),
  salesCertReconPrice: yup.number().label(ProductCatalogItemLabels.salesCertReconPrice).required(),
  salesNewPrice: yup.number().label(ProductCatalogItemLabels.salesNewPrice).required(),
  salesUsedPrice: yup.number().label(ProductCatalogItemLabels.salesUsedPrice).required(),
  isWebOrderDimPermissionsOverageDefault: yup.boolean().label(ProductCatalogItemLabels.isWebOrderDimPermissionsOverageDefault),
  customerXRef: yup.object()
});

export type AssignRuleForm = Omit<ProductCatalogItemPayload, 'productName' | 'productNumber' | 'manufacturer' | 'category'>;

export const assignRuleFormSchema: yup.SchemaOf<AssignRulePayload> = yup.object({
  defaultConfigType: yup.string().label(ProductCatalogItemLabels.defaultConfigType),
  defaultOverageOwner: yup.string().label(ProductCatalogItemLabels.defaultOverageOwner),
  deploymentType: yup.string().label(ProductCatalogItemLabels.deploymentType).required(),
  isCommonStock: yup.boolean().label(ProductCatalogItemLabels.isCommonStock),
  defaultOverageCondition: yup.string().label(ProductCatalogItemLabels.defaultOverageCondition),
  defaultOverageDisposition: yup.string().label(ProductCatalogItemLabels.defaultOverageDisposition),
  salesCertReconPrice: yup.number().label(ProductCatalogItemLabels.salesCertReconPrice),
  salesNewPrice: yup.number().label(ProductCatalogItemLabels.salesNewPrice),
  salesUsedPrice: yup.number().label(ProductCatalogItemLabels.salesUsedPrice),
  isWebOrderDimPermissionsOverageDefault: yup.boolean().label(ProductCatalogItemLabels.isWebOrderDimPermissionsOverageDefault),
  isOverridden: yup.boolean().label(ProductCatalogItemLabels.isOverridden),
  isActive: yup.boolean(),
  customerXRef: yup.object()
});

export interface ProductCatalogItem {
  auditMessages: string[];
  business: Business;
  category?: any;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  dataInheritanceLevel: string;
  defaultConfigType: string;
  defaultOverageOwner: string;
  deploymentType: string;
  division?: Division;
  documentType: string;
  id: string;
  isActive: boolean;
  isCommonStock: boolean;
  isDeleted: boolean;
  isOverridden: boolean;
  manufacturer?: any;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  defaultOverageCondition: string;
  defaultOverageDisposition: string;
  productName?: any;
  alternateItemId?: string;
  productNumber: string;
  salesCertReconPrice: number;
  salesNewPrice: number;
  salesUsedPrice: number;
  isWebOrderDimPermissionsOverageDefault: boolean;
  customerXRef?: {
    alternateItemId?: string;
    alternateItemIdLabel?: string;
    customerStandardCost?: number;
    modifiedDateTime?: string;
  };
}

interface Division {
  erpId: string;
  id: string;
  name: string;
}

interface Business {
  dataAreaId: string;
  erpId: string;
  id: string;
  name: string;
}
