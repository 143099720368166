import Icon from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import React from 'react';

export const BusinessSVG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 120 120" fill="#2471A3" xmlSpace="preserve">
      <g>
        <path d="M68.9,59.3L60,50.4l-8.9,8.9h-9.5V63h9.5l8.9,8.9l8.9-8.9h9.5v-3.7H68.9z M60,66.6l-5.5-5.5l5.5-5.5l5.5,5.5L60,66.6z"></path>
        <polygon points="93.7,59.8 93.7,69 91.6,69 91.6,59.8 86.7,57 80.5,60.5 80.5,74.2 92.7,81.1 104.8,74.2 104.8,60.5 98.6,57  "></polygon>
        <polygon points="28.4,59.8 28.4,69 26.3,69 26.3,59.8 21.4,57 15.2,60.5 15.2,74.2 27.3,81.1 39.5,74.2 39.5,60.5 33.3,57  "></polygon>
        <polygon points="34.2,50.6 34.2,42.8 27.3,38.9 20.5,42.8 20.5,50.6 27.3,54.5  "></polygon>
        <polygon points="99.5,50.6 99.5,42.8 92.7,38.9 85.8,42.8 85.8,50.6 92.7,54.5  "></polygon>
      </g>
    </svg>
  );
};

export const DivisionSVG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 120 120" fill="#1ABC9C" xmlSpace="preserve">
      <g>
        <polygon points="93.7,59.5 93.7,68.8 91.6,68.8 91.6,59.5 86.7,56.7 80.5,60.2 80.5,73.9 92.7,80.8 104.8,73.9 104.8,60.2    98.6,56.7  "></polygon>
        <polygon points="28.4,68.8 26.3,68.8 26.3,59.5 21.4,56.7 15.2,60.2 15.2,73.9 27.3,80.8 39.5,73.9 39.5,60.2 33.3,56.7 28.4,59.5     "></polygon>
        <polygon points="34.2,50.3 34.2,42.5 27.3,38.6 20.5,42.5 20.5,50.3 27.3,54.2  "></polygon>
        <polygon points="99.5,50.3 99.5,42.5 92.7,38.6 85.8,42.5 85.8,50.3 92.7,54.2  "></polygon>
        <polygon points="57.2,52.7 54.2,54.9 56.3,57.7 45,57.7 45,61.4 56.3,61.4 54.2,64.2 57.2,66.4 62.3,59.5  "></polygon>
        <polygon points="65.8,69.9 62.8,67.7 57.7,74.6 62.8,81.4 65.8,79.2 63.7,76.4 75,76.4 75,72.7 63.7,72.7  "></polygon>
      </g>
    </svg>
  );
};

export const OverrideSVG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 120 120" fill="#8E44AD" xmlSpace="preserve">
      <g>
        <polygon points="93.7,59.5 93.7,68.8 91.6,68.8 91.6,59.5 86.7,56.7 80.5,60.2 80.5,73.9 92.7,80.8 104.8,73.9 104.8,60.2    98.6,56.7  "></polygon>
        <polygon points="28.4,68.8 26.3,68.8 26.3,59.5 21.4,56.7 15.2,60.2 15.2,73.9 27.3,80.8 39.5,73.9 39.5,60.2 33.3,56.7 28.4,59.5     "></polygon>
        <polygon points="34.2,50.3 34.2,42.5 27.3,38.6 20.5,42.5 20.5,50.3 27.3,54.2  "></polygon>
        <polygon points="99.5,50.3 99.5,42.5 92.7,38.6 85.8,42.5 85.8,50.3 92.7,54.2  "></polygon>
        <path d="M57.2,52.7l-3,2.2l2.1,2.8H45v18.7h14.1l3.8,5l3-2.2l-2.1-2.8H75V57.7H60.9L57.2,52.7z M71.3,72.7h-7.6l2.1-2.8l-3-2.2   l-3.8,5H48.7V61.4h7.6l-2.1,2.8l3,2.2l3.8-5h10.4V72.7z"></path>
      </g>
    </svg>
  );
};

export const BusinessInheritanceIcon: React.FC<AntdIconProps> = () => {
  return <Icon component={BusinessSVG} style={{ width: 45, height: 45 }} />;
};

export const DivisionInheritanceIcon: React.FC = () => {
  return <Icon component={DivisionSVG} style={{ width: 45, height: 45 }} />;
};

export const OverrideInheritanceIcons: React.FC = () => {
  return <Icon component={OverrideSVG} style={{ width: 45, height: 45 }} />;
};
