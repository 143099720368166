import { BulkSearchActions } from 'components/molecules/BulkSearchActions';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { RulesNavigation } from './RulesNavigation';

export const BulkSearchControls: React.FC = () => {
  return (
    <Stack flexDirection="column" gap={16}>
      <Stack flexDirection="column">
        <AntPageTitle text="Bulk Search Product Catalog Items" />
      </Stack>
      <Stack justifyContent="space-between">
        <RulesNavigation />
        <BulkSearchActions />
      </Stack>
    </Stack>
  );
};
