import { Card, Col, Form, Row, Space, Tooltip, Typography } from 'antd';
import { ConditionsInput } from 'components/atoms/FormInputs/ConditionsInput';
import { DeploymentTypeInput } from 'components/atoms/FormInputs/DeploymentTypeInput';
import { DispositionsInput } from 'components/atoms/FormInputs/DispositionsInput';
import { OwnersInput } from 'components/atoms/FormInputs/OwnersInput';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { CurrencyInput } from 'components/UI/FormItems/CurrencyInput';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductCatalogItemQuery } from 'redux/services/julia/juliaApi';

interface Props {
  isInheritanceBusiness: boolean;
}

export const AssignRulesForm: FC<Props> = ({ isInheritanceBusiness }) => {
  const { id: entityId, productId } = useParams();

  const { data: itemResponse } = useGetProductCatalogItemQuery({ customerId: entityId || '', productId: productId || '' }, { skip: !productId });

  return (
    <Form layout="vertical">
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <Card
            style={{ height: '100%' }}
            headStyle={{ background: 'rgb(206, 213, 242)' }}
            title={
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
                General
              </Typography.Title>
            }>
            <Row gutter={[30, 0]}>
              <Col span={12}>
                <Form layout="vertical">
                  <Space direction="vertical" style={{ width: '100%' }} size={15}>
                    <Col>
                      <SwitchInput
                        fieldName="isCommonStock"
                        label={<Tooltip title="Lorem Ipsum">{AssignedProductsFormLabels.isCommonStock}</Tooltip>}
                        labelPosition="top"
                        isInheritanceBusiness={isInheritanceBusiness}
                      />
                    </Col>
                    <Col>
                      <DeploymentTypeInput isInheritanceBusiness={isInheritanceBusiness} />
                    </Col>
                    <Col>
                      <TextInput fieldName="defaultConfigType" label={AssignedProductsFormLabels.defaultConfigType} disabled={isInheritanceBusiness} />
                    </Col>
                    {itemResponse?.customerXRef && (
                      <Col>
                        <TextInput disabled fieldName="customerXRef.alternateItemId" label="Alternate Item Id" />
                      </Col>
                    )}
                  </Space>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{ height: '100%' }}
            headStyle={{ background: '#18a79984' }}
            title={
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
                Default Overage Dimensions
              </Typography.Title>
            }>
            <Row gutter={[30, 0]}>
              <Col span={12}>
                <Form layout="vertical">
                  <Space direction="vertical" style={{ width: '100%' }} size={15}>
                    <Col>
                      <OwnersInput isInheritanceBusiness={isInheritanceBusiness} />
                    </Col>
                    <Col>
                      <ConditionsInput isInheritanceBusiness={isInheritanceBusiness} />
                    </Col>
                    <Col>
                      <DispositionsInput isInheritanceBusiness={isInheritanceBusiness} />
                    </Col>
                  </Space>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{ height: '100%' }}
            headStyle={{ background: '#b0b2bc' }}
            title={
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
                Sales
              </Typography.Title>
            }>
            <Row gutter={[30, 0]}>
              <Col span={12}>
                <Form layout="vertical">
                  <Space direction="vertical" style={{ width: '100%' }} size={15}>
                    <Col>
                      <CurrencyInput fieldName="salesNewPrice" label={AssignedProductsFormLabels.salesNewPrice} disabled={isInheritanceBusiness} />
                    </Col>
                    <Col>
                      <CurrencyInput fieldName="salesCertReconPrice" label={AssignedProductsFormLabels.salesCertReconPrice} disabled={isInheritanceBusiness} />
                    </Col>
                    <Col>
                      <CurrencyInput fieldName="salesUsedPrice" label={AssignedProductsFormLabels.salesUsedPrice} disabled={isInheritanceBusiness} />
                    </Col>
                    {itemResponse?.customerXRef && (
                      <Col>
                        <CurrencyInput disabled fieldName="customerXRef.customerStandardCost" label="Customer Standard Cost" />
                      </Col>
                    )}
                  </Space>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
