import { Col, Form, Row, Space, Tooltip, Typography } from 'antd';
import { ConditionsInput } from 'components/atoms/FormInputs/ConditionsInput';
import { DeploymentTypeInput } from 'components/atoms/FormInputs/DeploymentTypeInput';
import { DispositionsInput } from 'components/atoms/FormInputs/DispositionsInput';
import { OwnersInput } from 'components/atoms/FormInputs/OwnersInput';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { CurrencyInput } from 'components/UI/FormItems/CurrencyInput';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductCatalogItemQuery } from 'redux/services/julia/juliaApi';

interface Props {
  isInheritanceBusiness: boolean;
}

export const AssignRulesFormModal: FC<Props> = ({ isInheritanceBusiness }) => {
  // const { id: customerId } = useParams();
  // const { data: catalogConfig } = useGetCatalogConfigQuery({ customerId: customerId as string }, { skip: !customerId });
  const { id: entityId, productId } = useParams();

  const { data: itemResponse } = useGetProductCatalogItemQuery({ customerId: entityId || '', productId: productId || '' }, { skip: !productId });

  return (
    <Form layout="vertical">
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row align="middle">
            <Col span={24} style={{ background: 'rgb(206, 213, 242)' }}>
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
                General
              </Typography.Title>
            </Col>
            <Col span={24} style={{ padding: 24 }}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <SwitchInput
                      fieldName="isCommonStock"
                      label={<Tooltip title="Lorem Ipsum">{AssignedProductsFormLabels.isCommonStock}</Tooltip>}
                      // labelPosition="reverse"
                      isInheritanceBusiness={isInheritanceBusiness}
                    />
                  </Col>
                  <Col>
                    <DeploymentTypeInput isInheritanceBusiness={isInheritanceBusiness} />
                  </Col>
                  <Col>
                    <TextInput fieldName="defaultConfigType" label={AssignedProductsFormLabels.defaultConfigType} disabled={isInheritanceBusiness} />
                  </Col>
                  {itemResponse?.customerXRef && (
                    <Col>
                      <TextInput disabled fieldName="customerXRef.alternateItemId" label="Alternate Item Id" />
                    </Col>
                  )}
                </Space>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ background: '#18a79984' }}>
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
                Default Overage Dimensions
              </Typography.Title>
            </Col>
            <Col span={24} style={{ padding: 24 }}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <OwnersInput isInheritanceBusiness={isInheritanceBusiness} />
                  </Col>
                  <Col>
                    <ConditionsInput isInheritanceBusiness={isInheritanceBusiness} />
                  </Col>
                  <Col>
                    <DispositionsInput isInheritanceBusiness={isInheritanceBusiness} />
                  </Col>
                </Space>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ background: '#b0b2bc' }}>
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
                Sales
              </Typography.Title>
            </Col>
            <Col span={24} style={{ padding: 24 }}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <CurrencyInput fieldName="salesNewPrice" label={AssignedProductsFormLabels.salesNewPrice} disabled={isInheritanceBusiness} />
                  </Col>
                  <Col>
                    <CurrencyInput fieldName="salesCertReconPrice" label={AssignedProductsFormLabels.salesCertReconPrice} disabled={isInheritanceBusiness} />
                  </Col>
                  <Col>
                    <CurrencyInput fieldName="salesUsedPrice" label={AssignedProductsFormLabels.salesUsedPrice} disabled={isInheritanceBusiness} />
                  </Col>
                  {itemResponse?.customerXRef && (
                    <Col>
                      <CurrencyInput disabled fieldName="customerXRef.customerStandardCost" label="Customer Standard Cost" />
                    </Col>
                  )}
                </Space>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
