import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useLazyGetCatalogConfigsQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setIsTriggerLoading } from 'redux/slices/appSlice';
import { setCachedBusinessList, setCachedCatalogConfig } from 'redux/slices/businessListSlice';

export const RefetchButton = (): JSX.Element => {
  const dispatch = useDispatch();

  const lastRefetch = localStorage.getItem('currentDateProdCatalog');

  const [getBussinessLazy, { isFetching, isLoading }] = useLazyGetBusinessesQuery();
  const [getLazyCatalogConfigs, { isLoading: isConfigLoading, isFetching: isConfigFetching }] = useLazyGetCatalogConfigsQuery();

  const [refetchDate, setRefetchDate] = useState(lastRefetch);

  const isDocumentLoading = Boolean(isLoading || isFetching || isConfigFetching || isConfigLoading);

  const handleClick = async (): Promise<void> => {
    try {
      dispatch(setIsTriggerLoading(true));
      const response = await getBussinessLazy({ includeDivisions: true }).unwrap();
      const configResponse = await getLazyCatalogConfigs({ dataInheritanceLevels: 'Business,Division,BusinessWithDivisionOverride' }).unwrap();

      dispatch(setCachedBusinessList(response.data));
      dispatch(setCachedCatalogConfig(configResponse?.data));

      dispatch(setIsTriggerLoading(false));
      dispatch(setCachedBusinessList(response.data));
      setRefetchDate(localStorage.getItem('currentDateProdCatalog'));
      localStorage.setItem('currentDateProdCatalog', JSON.stringify(new Date()));
      message.success('Customers successfully refreshed.');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      dispatch(setIsTriggerLoading(false));
    }
  };

  const formatDate = (): string => {
    const dateObj = refetchDate ? new Date(JSON.parse(refetchDate)) : 'Data has never been refreshed, Click here to refresh the list of customers';

    if (typeof dateObj === 'string') return dateObj;
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString();
    const formattedDate = `Data last cached on ${date} at ${time}. Click here to refresh the list of customers`;

    return formattedDate;
  };

  return (
    <Tooltip title={formatDate()}>
      <Button disabled={isDocumentLoading} icon={<RedoOutlined />} onClick={handleClick}>
        Refresh Customer List
      </Button>
    </Tooltip>
  );
};
