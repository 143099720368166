import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductCatalogItemsParams } from 'models/ProductCatalog';

const initialState: ProductCatalogItemsParams = {
  offset: 0,
  take: 25,
  includeInactiveData: true,
  returnInactiveDataOnly: undefined,
  includeDeletedData: undefined,
  returnDeletedDataOnly: undefined,
  productNumberContains: undefined,
  productNumbers: undefined,
  isCommonStock: undefined,
  isWebOrderDimPermissionsOverageDefault: undefined,
  isOverridden: undefined,
  defaultConfigTypeContains: undefined,
  defaultOverageConditionContains: undefined,
  defaultOverageDispositionContains: undefined,
  defaultOverageOwnerContains: undefined,
  deploymentTypeContains: undefined,
  alternateItemIdContains: undefined
};

export const juliaProductCatalogParams = createSlice({
  name: 'juliaProductCatalogParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ProductCatalogItemsParams['offset']>) => {
      state.offset = payload;
    },
    setTake: (state, { payload }: PayloadAction<ProductCatalogItemsParams['take']>) => {
      state.take = payload;
      state.offset = 0;
    },
    setAlternateItemIdContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['alternateItemIdContains']>) => {
      state.alternateItemIdContains = payload;
      state.offset = 0;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<ProductCatalogItemsParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
      state.offset = 0;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<ProductCatalogItemsParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
      state.offset = 0;
    },
    setIncludeDeletedData: (state, { payload }: PayloadAction<ProductCatalogItemsParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
      state.offset = 0;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ProductCatalogItemsParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
      state.offset = 0;
    },
    setProductNumberContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['productNumberContains']>) => {
      state.productNumberContains = payload;
      state.offset = 0;
    },
    setProductNumbers: (state, { payload }: PayloadAction<ProductCatalogItemsParams['productNumbers']>) => {
      state.productNumbers = payload;
      state.offset = 0;
    },
    setIsCommonStock: (state, { payload }: PayloadAction<ProductCatalogItemsParams['isCommonStock']>) => {
      state.isCommonStock = payload;
      state.offset = 0;
    },
    setIsWebOrderDimensionPermission: (state, { payload }: PayloadAction<ProductCatalogItemsParams['isWebOrderDimPermissionsOverageDefault']>) => {
      state.isWebOrderDimPermissionsOverageDefault = payload;
      state.offset = 0;
    },
    setIsOverridden: (state, { payload }: PayloadAction<ProductCatalogItemsParams['isOverridden']>) => {
      state.isOverridden = payload;
      state.offset = 0;
    },
    setDefaultConfigTypeContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['defaultConfigTypeContains']>) => {
      state.defaultConfigTypeContains = payload;
      state.offset = 0;
    },
    setDeploymentTypeTypeContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['deploymentTypeContains']>) => {
      state.deploymentTypeContains = payload;
      state.offset = 0;
    },
    setDefaultOverageOwnerContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['defaultOverageOwnerContains']>) => {
      state.defaultOverageOwnerContains = payload;
      state.offset = 0;
    },
    setDefaultOverageDispositionContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['defaultOverageDispositionContains']>) => {
      state.defaultOverageDispositionContains = payload;
      state.offset = 0;
    },
    setDefaultOverageConditionContains: (state, { payload }: PayloadAction<ProductCatalogItemsParams['defaultOverageConditionContains']>) => {
      state.defaultOverageConditionContains = payload;
      state.offset = 0;
    },
    resetParams(state) {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setAlternateItemIdContains,
  setIncludeDeletedData,
  setIncludeInactiveData,
  setProductNumberContains,
  setReturnDeletedDataOnly,
  setIsCommonStock,
  setIsWebOrderDimensionPermission,
  setOffset,
  setProductNumbers,
  setReturnInactiveDataOnly,
  resetParams,
  setTake,
  setIsOverridden,
  setDefaultConfigTypeContains,
  setDeploymentTypeTypeContains,
  setDefaultOverageOwnerContains,
  setDefaultOverageConditionContains,
  setDefaultOverageDispositionContains
} = juliaProductCatalogParams.actions;
