import { Card, Col, List, Row, Typography } from 'antd';
import { BusinessInheritanceIcon, DivisionInheritanceIcon, OverrideInheritanceIcons } from 'components/UI/InheritanceIcons';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCustomerSearch } from 'redux/slices/businessListSlice';

const { Paragraph } = Typography;

interface Props {
  division: Business['divisions'][number];
}
const styles: InlineStylesModel = {
  wrapper: {
    height: '100%'
  },
  container: {
    padding: 2
  },
  title: {
    fontSize: '0.85em',
    // whiteSpace: 'nowrap',
    // ParagraphOverflow: 'ellipsis',
    color: 'green',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const DivisionListItem = ({ division }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ******************** Variables / Functions ******************** */
  const configurationType = division && division.productCatalogConfiguration && division.productCatalogConfiguration.dataInheritanceLevel;

  const mapConfigurationTypeIcon = (): JSX.Element => {
    switch (configurationType) {
      case 'Business':
        return <BusinessInheritanceIcon />;

      case 'Division':
        return <DivisionInheritanceIcon />;

      case 'BusinessWithDivisionOverride':
        return <OverrideInheritanceIcons />;

      default:
        return <div style={{ height: 45, display: 'flex', alignItems: 'center' }}>Not Set</div>;
    }
  };

  const handleEditDivision = (): void => {
    navigate(`/division/${division.id}`);
    dispatch(setCustomerSearch(''));
  };

  /* ******************** Renderer ******************** */
  return (
    <List.Item style={{ marginBottom: 4, height: '100%' }}>
      <Card hoverable style={styles.wrapper} bodyStyle={styles.container} onClick={handleEditDivision}>
        <Row align="middle">
          <Col style={styles.iconContainer} span={4}>
            {mapConfigurationTypeIcon()}
          </Col>
          <Col style={styles.titleContainer} span={20}>
            <Row>
              <Col span={24}>
                <Paragraph style={styles.title} ellipsis>
                  {division.name}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Paragraph style={styles.subTitle}>{division.erpId}</Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
