import { Spin } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { AssignedRulesContextMenu } from 'components/atoms/AssignedRulesContextMenu';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { JobsInProcess } from 'components/atoms/JobsInProcess';
import { AssignedRulesEditModal } from 'components/molecules/AssignedRulesEditModal';
import { AssignedRulesControls } from 'components/organisms/AssignedRulesControls';
import { RulesTable } from 'components/organisms/RulesTable';
import { ProductCatalogItem } from 'models/ProductCatalog';
import { RenderedCell } from 'rc-table/lib/interface';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCatalogJobsQuery, useGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { setOffset, setTake } from 'redux/services/julia/productCatalogParams';
import { useCreateCatalogConfigMutation, useGetCatalogConfigQuery, useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { addSelectedRule, setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const AssignedRulesPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isBusinessEdit = pathname.includes('business/');

  const { juliaProductCatalogParams, assignedRules, rudyCadabbyApi } = useAppSelector((state) => state);

  const [isPollingJob, _setIsPollingJobStarted] = useState(false);

  /* ****************** API Hooks ****************** */
  const {
    data: productCatalogItemsResponse,
    isLoading: isResponseLoading,
    isFetching: isResponseFetching,
    refetch
  } = useGetProductCatalogItemsQuery({ customerId: entityId as string, params: juliaProductCatalogParams }, { skip: !entityId });

  const { data: catalogConfig, isLoading: isCatalogConfigLoading, isError: isCatalogConfigError } = useGetCatalogConfigQuery({ customerId: entityId as string }, { skip: !entityId });
  const [createBusinessCatalog, { isLoading: isCreateCatalogLoading }] = useCreateCatalogConfigMutation();
  const { data: xRefConfig } = useGetXrefConfigQuery({ customerId: entityId as string });

  // Check to see if there are any jobs behinde the scenes and prevent user to use the app until its finished
  const { data: catalogJobsData, isLoading: isCatalogJobsLoading } = useGetCatalogJobsQuery(
    { divisionId: entityId as string },
    { skip: isBusinessEdit || !entityId || isCatalogConfigLoading || isCatalogConfigError, pollingInterval: 2000 }
  );

  /* ******************** Variables / Functions ******************** */
  const isLoading = isResponseLoading || isCatalogJobsLoading;
  const isFetching = isResponseFetching;
  const data = productCatalogItemsResponse?.data;
  const totalCount = productCatalogItemsResponse?.totalCount || 0;
  const isNoInheritanceSelected = catalogConfig?.dataInheritanceLevel === undefined;
  const isBusinessOnly = catalogConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');
  const selectedKeys =
    assignedRules.selectedRules.length && productCatalogItemsResponse ? assignedRules.selectedRules.map((id) => productCatalogItemsResponse.data.find((item) => item.id === id)?.id ?? '') : [];

  const handleUpdateRecord = (productId: string): void => {
    navigate(`rules/${productId}`);
  };

  const handleBusinessSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductCatalogItem[]): void => {
    dispatch(setSelectedRules(selectedRows.map((item) => item.id)));
  };

  const handleDivisionSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductCatalogItem[]): void => {
    // Handle deslect all
    if (selectedKeys.length === selectedRows.filter((item) => item.isOverridden).map((item) => item.id).length) {
      dispatch(setSelectedRules([]));

      return;
    }

    // Handle all the rest
    dispatch(setSelectedRules(selectedRows.filter((item) => item.isOverridden).map((item) => item.id)));
  };

  const handlePageChange = (page: number, pageSize: number): void => {
    dispatch(setTake(pageSize));
    dispatch(setOffset((page - 1) * pageSize));
  };

  const handleTableRightClick = (idNumber: string): void => {
    if (assignedRules.selectedRules.includes(idNumber) === false) dispatch(addSelectedRule(idNumber));
  };

  const checkedCellJSX = (checked: boolean, record: ProductCatalogItem, index: number, originalNode: React.ReactNode): React.ReactNode | RenderedCell<ProductCatalogItem> | undefined => {
    if (!record.isOverridden && !isBusinessEdit) return <></>;

    return originalNode;
  };

  const tableLoading = {
    spinning: isLoading || (isFetching && juliaProductCatalogParams.offset === 0),
    indicator: <BlockLoader direction="loader loader--slideUp" />
  };

  const rowSelectionConfig: TableRowSelection<ProductCatalogItem> = {
    type: 'checkbox',
    onChange: isBusinessEdit ? handleBusinessSelectRow : handleDivisionSelectRow,
    renderCell: checkedCellJSX,
    selectedRowKeys: selectedKeys
  };

  // Create catalog config if business doesnt have one
  useEffect(() => {
    if (isBusinessEdit && isCatalogConfigError && entityId) createBusinessCatalog({ customerId: entityId, payload: { dataInheritanceLevel: 'Business' } });
  }, [createBusinessCatalog, entityId, isBusinessEdit, isCatalogConfigError]);

  useEffect(() => {
    if (!isPollingJob && !isCatalogConfigError && !isCatalogConfigLoading && catalogJobsData && catalogJobsData.length > 0) _setIsPollingJobStarted(true);
    if (isPollingJob && catalogJobsData?.length === 0) {
      _setIsPollingJobStarted(false);
      refetch();
    }
  }, [rudyCadabbyApi, catalogJobsData, isCatalogConfigError, isCatalogConfigLoading, isPollingJob, refetch]);
  /* ****************** Render ****************** */
  if (!isCatalogConfigError && !isCatalogConfigLoading && catalogJobsData && catalogJobsData.length > 0) return <JobsInProcess />;
  if (isNoInheritanceSelected && !isCatalogConfigLoading) {
    navigate('/unauthorized');
  }

  return (
    <Spin spinning={!catalogConfig && isCatalogConfigLoading && isCatalogConfigError} indicator={<BlockLoader direction="loader loader--slideUp" />}>
      <AssignedRulesControls />
      <AssignedRulesContextMenu>
        <RulesTable
          xRefLabel={xRefConfig?.alternateItemIdLabel}
          onProductNumber={handleUpdateRecord}
          loading={tableLoading}
          dataSource={data}
          scroll={{ x: 'max-content' }}
          rowSelection={!isBusinessOnly ? rowSelectionConfig : undefined}
          onRow={(record): { onContextMenu: (event: MouseEvent) => void } => ({
            onContextMenu: (event): void => {
              event.preventDefault();
              if (record.isOverridden || isBusinessEdit) handleTableRightClick(record.id);
            }
          })}
          size="small"
          rowKey={(item): string => item.id}
          pagination={{ total: totalCount, defaultPageSize: juliaProductCatalogParams.take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
        />
      </AssignedRulesContextMenu>
      <AssignedRulesEditModal />
    </Spin>
  );
};
