import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { setDefaultOverageConditionContains } from 'redux/services/julia/productCatalogParams';
import { ReduxState, useAppDispatch, useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const ConditionsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { animalsConditionsParams } = useAppSelector((state) => state);
  const { data: conditionsResponse, isLoading: isConditionsLoading } = useGetConditionsQuery(animalsConditionsParams);
  const { defaultOverageConditionContains } = useSelector((state: ReduxState) => state.juliaProductCatalogParams);
  const dispatch = useAppDispatch();

  /* ******************** Variables ******************** */
  const conditionsOptions: DefaultOptionType[] | undefined = conditionsResponse?.data?.map((condition) => ({ value: condition.name, label: condition.name }));

  /* ******************** Functions ******************** */

  const handleChange = (value: string): void => {
    dispatch(setDefaultOverageConditionContains(value === undefined ? undefined : value));
  };

  /******** cleanup *********/
  useEffect(() => {
    return (): void => {
      dispatch(setDefaultOverageConditionContains(undefined));
    };
  }, [dispatch]);

  return (
    <FancySelect
      placeholder="Overage Condition"
      value={defaultOverageConditionContains === undefined ? undefined : defaultOverageConditionContains}
      options={conditionsOptions}
      loading={isConditionsLoading}
      disabled={false}
      onChange={handleChange}
      showSearch
      width={170}
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
