import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Menu, message } from 'antd';
import { LogoUpload } from 'components/common/LogoUpload';
import { UserForm } from 'components/common/UserForm';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Stack } from 'components/UI/Stack';
import { FormikProvider, useFormik } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { EditProfile } from 'models/Users';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { setUser } from 'redux/slices/appSlice';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  //
};

export const EditProfilePage: React.FC = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const { user: reduxUser } = useSelector((state: ReduxState) => state.app);

  const profileId = reduxUser?.sub as string;

  /* ******************** API Hooks ******************** */

  const formik = useFormik<EditProfile>({
    enableReinitialize: true,
    initialValues: {
      email: reduxUser ? reduxUser.email : '',
      givenName: reduxUser ? reduxUser.given_name : '',
      familyName: reduxUser ? reduxUser.family_name : '',
      phoneNumber: reduxUser ? reduxUser['https://acuity.mdsiinc.com/user/user_metadata'].phone_number : ''
    },
    onSubmit: async (values) => {
      if (!reduxUser) {
        message.error("User doesn't exist");

        return;
      }
      try {
        const {
          email,
          given_name,
          family_name,
          user_metadata: { phone_number },
          name
        } = await updateUser({ userId: profileId, payload: { ...reduxUser, ...values } }).unwrap();

        dispatch(setUser({ ...reduxUser, email, given_name, family_name, phone_number: typeof phone_number === 'string' ? phone_number : undefined, name }));
        message.success(`User ${name} was successfully updated!`);
      } catch (error) {
        console.log(error);
        message.error('User failed to be updated!');
      }
    }
  });

  /* ******************** Functions ******************** */

  const handleClose = (): void => {
    navigate('/');
  };

  const handleSave = async (close?: boolean): Promise<void> => {
    await formik.submitForm();
    if (close) {
      handleClose();
    }
  };
  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Stack flexDirection="column">
      <Col style={{ padding: 10, background: 'rgb(206, 213, 242)' }}>
        <Stack justifyContent="space-between">
          <AntPageTitle text={`Edit Profile`} />
          <Stack>
            <Button onClick={handleClose} icon={<ArrowLeftOutlined />}>
              Back
            </Button>
            <Dropdown.Button overlay={menuJSX} onClick={(): Promise<void> => handleSave(true)} loading={formik.isSubmitting} type="primary" icon={<SaveOutlined />}>
              Save and Exit
            </Dropdown.Button>
          </Stack>
        </Stack>
      </Col>
      <Card>
        <FormikProvider value={formik}>
          <UserForm />
        </FormikProvider>
      </Card>
      <Card>
        <LogoUpload profileData={reduxUser} />
      </Card>
    </Stack>
  );
};
