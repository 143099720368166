import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetDispositionsQuery } from 'redux/services/animals/animalsApi';
import { setDefaultOverageDispositionContains } from 'redux/services/julia/productCatalogParams';
import { ReduxState, useAppDispatch, useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const DispositionsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { animalsDispositionsParams } = useAppSelector((state) => state);
  const { data: dispositionsResponse, isLoading: isDispositionsLoading } = useGetDispositionsQuery(animalsDispositionsParams);
  const dispatch = useAppDispatch();
  const { defaultOverageDispositionContains } = useSelector((state: ReduxState) => state.juliaProductCatalogParams);

  /* ******************** Variables ******************** */
  const dispositionOptions: DefaultOptionType[] | undefined = dispositionsResponse?.data?.map((disposition) => ({ value: disposition.name, label: disposition.name }));

  /* ******************** Functions ******************** */

  const handleChange = (value: string): void => {
    dispatch(setDefaultOverageDispositionContains(value === undefined ? undefined : value));
  };

  /********** cleanup ***********/
  useEffect(() => {
    return (): void => {
      dispatch(setDefaultOverageDispositionContains(undefined));
    };
  }, [dispatch]);

  return (
    <FancySelect
      placeholder="Overage Disposition"
      options={dispositionOptions}
      loading={isDispositionsLoading}
      disabled={false}
      onChange={handleChange}
      value={defaultOverageDispositionContains === undefined ? undefined : defaultOverageDispositionContains}
      width={170}
      showSearch
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
