import { Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import { FC } from 'react';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { useAppSelector } from 'redux/store';

interface Props {
  hideLabel?: boolean;
  isInheritanceBusiness: boolean;
}

export const ConditionsInput: FC<Props> = ({ hideLabel, isInheritanceBusiness }) => {
  /* ******************** Hooks ******************** */
  const { animalsConditionsParams } = useAppSelector((state) => state);
  const { data: conditionsResponse, isLoading: isConditionsLoading } = useGetConditionsQuery(animalsConditionsParams);

  /* ******************** Variables ******************** */
  const conditionsOptions: DefaultOptionType[] | undefined = conditionsResponse?.data?.map((condition) => ({ value: condition.name, label: condition.name }));

  return (
    <SelectInput
      fieldName="defaultOverageCondition"
      label={
        hideLabel ? undefined : (
          <Tooltip title="This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.">
            {AssignedProductsFormLabels.defaultOverageCondition}
          </Tooltip>
        )
      }
      options={conditionsOptions}
      loading={isConditionsLoading}
      disabled={isInheritanceBusiness}
      showSearch
      filterOption={(input, option): boolean => {
        if (typeof option?.value !== 'number') return (option?.value as string)?.toLowerCase().includes(input.toLowerCase());

        return false;
      }}
    />
  );
};
