import { DefaultOptionType } from 'antd/lib/select';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { DeploymentTypes, DeploymentTypesLabels } from 'models/ProductCatalog';
import { AssignedProductsFormLabels } from 'models/UpdateProductCatalog';
import React, { FC } from 'react';

interface Props {
  hideLabel?: boolean;
  isInheritanceBusiness: boolean;
}

export const DeploymentTypeInput: FC<Props> = ({ hideLabel, isInheritanceBusiness }) => {
  /* ******************** Variables ******************** */
  const deploymentTypesOptions: DefaultOptionType[] = [
    { label: DeploymentTypesLabels.AllOrders, value: DeploymentTypes.AllOrders },
    { label: DeploymentTypesLabels.SparingOrder, value: DeploymentTypes.SparingOrder },
    { label: DeploymentTypesLabels.StandardOrder, value: DeploymentTypes.StandardOrder }
  ];

  return <SelectInput fieldName="deploymentType" label={hideLabel ? undefined : AssignedProductsFormLabels.deploymentType} options={deploymentTypesOptions} disabled={isInheritanceBusiness} />;
};
