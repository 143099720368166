import { Button, Typography } from 'antd';
import { Stack } from 'components/UI';
import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as xlsx from 'xlsx';

interface Props {
  errorItems: { errors: string[]; productNumber: string }[];
}

export const ImportHeader: FC<Props> = ({ errorItems }): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');
  const navigate = useNavigate();

  /* ******************** Functions ******************** */
  const handleNavigateBack = (): void => {
    navigate(`/${isBusinessEdit ? 'business' : 'division'}/${entityId}`);
  };

  const handleDownloadErrors = async (): Promise<void> => {
    const worksheet = xlsx.utils.json_to_sheet(errorItems.map((item) => ({ ...item, errors: item.errors.join('\n') })));
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheet, 'default');
    const excelBuffer = await xlsx.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const uri = window.URL.createObjectURL(excelBlob);
    const link = document.createElement('a');

    link.setAttribute('href', uri);
    link.setAttribute('download', 'subscription-import-errors.xlsx');

    link.click();
    URL.revokeObjectURL(uri);
  };

  return (
    <Stack justifyContent="space-between">
      <Typography.Title level={3}>Import Product Catalog</Typography.Title>
      <Stack>
        <Button onClick={handleNavigateBack}>Back</Button>
        <Button onClick={handleDownloadErrors} type="primary" disabled={!errorItems.length}>
          Export Errors
        </Button>
      </Stack>
    </Stack>
  );
};
