import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Tooltip, Typography } from 'antd';
import { Stack } from 'components/UI';
import { AntCardTitle } from 'components/UI/AntCardTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ReleasedProduct } from 'models/ReleasedProduct';
import React from 'react';
import { addSelectedProduct, removeSelectedProduct } from 'redux/slices/productSearchSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
const { Text } = Typography;

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  }
};

interface Props {
  product: ReleasedProduct;
  isAssigned: boolean;
  isAssignedLoading: boolean;
  isRecovery: boolean;
}

export const ProductSearchItem = ({ product, isAssigned, isAssignedLoading, isRecovery }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { selectedProducts } = useAppSelector((state) => state.productSearch);
  const dispatch = useAppDispatch();

  /* ****************** API Hooks ****************** */

  /* ******************** Functions / Variables ******************** */
  const isSelected = selectedProducts.some((item) => item.productNumber === product.productNumber);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeSelectedProduct(product));
    } else {
      dispatch(addSelectedProduct(product));
    }
  };

  const extraJSX = !isAssigned ? (
    <Button loading={isAssignedLoading} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
  ) : isRecovery ? (
    <Text type="warning">Recycled</Text>
  ) : (
    <Text type="success">Assigned</Text>
  );

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 8 }}
        style={isAssigned ? { ...styles.container, opacity: 0.5 } : styles.container}
        title={
          <Tooltip title={product.searchName}>
            <AntCardTitle text={product.productNumber} />
          </Tooltip>
        }
        extra={extraJSX}>
        <Row gutter={[8, 8]} align="top" style={{}}>
          <Col span={24}>
            <Text type="secondary" style={styles.description}>
              {product.productDescription}
            </Text>
          </Col>
          <Col span={12}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              <Text>Category</Text>
              <Text type="secondary">{product.productGroupId || 'NA'}</Text>
            </Stack>
          </Col>
          <Col span={12}>
            <Stack flexDirection="column" gap={5} lineHeight={1} alignItems="flex-end">
              <Text>Manufacturer</Text>
              <Text type="secondary">{product.manufacturerName || 'NA'}</Text>
            </Stack>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
