import { BackTop, Button, Col, Layout, Row, Spin, Table, Typography } from 'antd';
import { AtatWalker } from 'components/atoms/AtatWalker';
import { Loader } from 'components/common/Loader';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useGetApiNameQuery as useAbbyCadabbyName, useGetVersionQuery as useAbbyCadabbyVersion } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetApiNameQuery as useAnimalsName, useGetVersionQuery as useAnimalsVersion } from 'redux/services/animals/animalsApi';
import { useGetApiNameQuery as useCookieMonsterName, useGetVersionQuery as useCookieMonsterVersion } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useGetApiNameQuery as useDonaldGrumpName, useGetVersionQuery as useDonaldGrumpVersion } from 'redux/services/donaldGrump/donaldGrumpApi';
import { useGetApiNameQuery as useFreddyCadabbyName, useGetVersionQuery as useFreddyCadabbyVersion } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { useGetApiNameQuery as useJuliaName, useGetVersionQuery as useJuliaVersion } from 'redux/services/julia/juliaApi';
import { resetParams as resetJuliaParams } from 'redux/services/julia/productCatalogParams';
import { useGetApiNameQuery as useRudyCadabbyName, useGetVersionQuery as useRudyCadabbyVersion } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { useAppDispatch } from 'redux/store';
import { globalStyles } from '../common/GlobalStyles';
import '../common/styles/checkmarkStyle.less';

const styles: InlineStylesModel = {
  headerTitle: {
    color: 'rgb(67, 67, 73)',
    font: isMobileOnly ? '500 0.75rem Poppins' : '500 1rem Poppins',
    fontSize: 16
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobileOnly ? 'center' : 'none'
  },
  statusIcon: {
    fontSize: '1.5rem',
    marginRight: isMobileOnly ? '0px' : '15px'
  },
  statusText: {
    display: isMobileOnly ? 'none' : 'block'
  },
  tableBody: {
    backgroundColor: '#fff',
    fontSize: isMobileOnly ? '0.7em' : '1em',
    borderTop: '2px solid black'
  }
};

enum ApiStatus {
  online = 'Online',
  offline = 'Offline'
}

export const About: React.FunctionComponent = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: abbyCadabbyApiVersion, isLoading: isLoadingAbbyCadabby } = useAbbyCadabbyVersion();
  const { data: abbyCadabbyApiName, isLoading: isLoadingAbbyCadabbyName } = useAbbyCadabbyName();

  const { data: cookieMonsterApiVersion, isLoading: isLoadingCookieMonster } = useCookieMonsterVersion();
  const { data: cookieMonsterApiName, isLoading: isLoadingCookieMonsterName } = useCookieMonsterName();

  const { data: freddyCadabbyApiVersion, isLoading: isLoadingFreddyCadabby } = useFreddyCadabbyVersion();
  const { data: freddyCadabbyApiName, isLoading: isLoadingFreddyCadabbyName } = useFreddyCadabbyName();

  const { data: animalsApiVersion, isLoading: isLoadingAnimals } = useAnimalsVersion();
  const { data: animalsApiName, isLoading: isLoadingAnimalsName } = useAnimalsName();

  const { data: donaldGrumpApiVersion, isLoading: isLoadingDonaldGrump } = useDonaldGrumpVersion();
  const { data: donaldGrumpApiName, isLoading: isLoadingDonaldGrumpName } = useDonaldGrumpName();

  const { data: juliaApiVersion, isLoading: isLoadingJulia } = useJuliaVersion();
  const { data: juliaApiName, isLoading: isLoadingJuliaName } = useJuliaName();

  const { data: rudyCadabbyApiVersion, isLoading: isLoadingRudyCadabby } = useRudyCadabbyVersion();
  const { data: rudyCadabbyApiName, isLoading: isLoadingRudyCadabbyName } = useRudyCadabbyName();

  const columnHeaders = [
    {
      dataIndex: 'apiName',
      key: 'apiName',
      title: 'API Name'
    },
    {
      dataIndex: 'version',
      key: 'version',
      title: 'Version'
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status'
    }
  ];
  const handleHomePage = (): void => {
    dispatch(resetJuliaParams());
    navigate(`/`);
  };
  /* ******************** Table Data ******************** */
  const abbyCadabbyTableData = {
    version: abbyCadabbyApiVersion || 'failed to get version...',
    key: abbyCadabbyApiName,
    status: !isLoadingAbbyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {abbyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{abbyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: abbyCadabbyApiName ? (
      <Row>
        <Col>{abbyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const cookieMonsterTableData = {
    version: cookieMonsterApiVersion || 'failed to get version...',
    key: cookieMonsterApiName,
    status: !isLoadingCookieMonster ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {cookieMonsterApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{cookieMonsterApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: cookieMonsterApiName ? (
      <Row>
        <Col>{cookieMonsterApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const animalsTableData = {
    version: animalsApiVersion || 'failed to get version...',
    key: animalsApiName,
    status: !isLoadingAnimals ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {animalsApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{animalsApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: animalsApiName ? (
      <Row>
        <Col>{animalsApiName}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const donaldGrumpTableData = {
    version: donaldGrumpApiVersion || 'failed to get verion...',
    key: donaldGrumpApiName,
    status: !isLoadingDonaldGrump ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {donaldGrumpApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{donaldGrumpApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: donaldGrumpApiName ? (
      <Row>
        <Col>{donaldGrumpApiName}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const juliaTableData = {
    version: juliaApiVersion || 'failed to get verion...',
    key: juliaApiName,
    status: !isLoadingJulia ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {juliaApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{juliaApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: juliaApiName ? (
      <Row>
        <Col>{juliaApiName}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const rudyCadabbyTableData = {
    version: rudyCadabbyApiVersion || 'failed to get verion...',
    key: rudyCadabbyApiName,
    status: !isLoadingRudyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {rudyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{rudyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: rudyCadabbyApiName ? (
      <Row>
        <Col>{rudyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };

  const freddyCadabbyTableData = {
    version: freddyCadabbyApiVersion || 'failed to get version...',
    key: freddyCadabbyApiName,
    status: !isLoadingFreddyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {freddyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{freddyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: freddyCadabbyApiName ? (
      <Row>
        <Col>{freddyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };

  const mainAppVersion = (): string => {
    const appName = process.env.REACT_APP_APPLICATION_NAME as string;
    const versionNumber = process.env.REACT_APP_VERSION_NUMBER as string;
    const splitVersion = versionNumber.split('-');

    return `${appName} ${splitVersion[splitVersion.length - 1]}`;
  };

  if (isLoadingAbbyCadabby) {
    return <Loader />;
  }

  return (
    <Layout.Content style={{ ...globalStyles.addressGridContainer }}>
      <BackTop />
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <AntPageTitle text="About Website" />
              <Typography.Link href="/" style={styles.headerTitle}>
                {mainAppVersion()}
              </Typography.Link>
            </Col>
            <Col>
              <Button onClick={handleHomePage} type="primary">
                Back to Home
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={13} sm={24}>
          <Table
            columns={columnHeaders}
            bordered={true}
            pagination={false}
            dataSource={[abbyCadabbyTableData, cookieMonsterTableData, animalsTableData, donaldGrumpTableData, juliaTableData, rudyCadabbyTableData, freddyCadabbyTableData]}
            style={styles.tableBody}
          />
        </Col>
        <Col md={10} sm={24}>
          <AtatWalker />
        </Col>
      </Row>
    </Layout.Content>
  );
};
