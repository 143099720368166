import { Row, Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import React, { FC } from 'react';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string | React.ReactNode;
  labelPosition?: 'top' | 'right';
  isInheritanceBusiness: boolean;
}

export const SwitchInput: FC<Props> = ({ fieldName, label, labelPosition, isInheritanceBusiness, ...rest }) => {
  const [{ value }, _, { setValue }] = useField<boolean>(fieldName);

  return (
    <Row>
      {!!label && <Typography.Text style={{ marginRight: 10 }}>{label}</Typography.Text>}
      <Switch checked={value} onChange={(checked): void => setValue(checked)} disabled={isInheritanceBusiness || rest.disabled} />
    </Row>
  );
};
