import { Space } from 'antd';
import { CategoryFilter, ManufacturerFilter, ProductNameFilter, ProductNumberFilter } from 'components/atoms/ProductSearchFilters';
import React from 'react';

export const ProductSearchFilters = (): JSX.Element => {
  return (
    <Space>
      <ProductNumberFilter />
      <ProductNameFilter />
      <CategoryFilter />
      <ManufacturerFilter />
    </Space>
  );
};
