import { Table, Tooltip, Typography } from 'antd';
import { ColumnsType, ColumnType, TableProps } from 'antd/lib/table';
import { OverridenSwitch } from 'components/atoms/Inputs/OverridenSwitch';
import { ProductCatalogItem } from 'models/ProductCatalog';
import { useLocation, useParams } from 'react-router-dom';
import { useGetCatalogConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { formatCurrency } from 'utils/formatters';
const { Text, Link } = Typography;

interface Props extends TableProps<ProductCatalogItem> {
  onProductNumber?: (productNumber: string) => void;
  xRefLabel?: string;
}

export const RulesTable = ({ onProductNumber, xRefLabel, ...rest }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const { id: customerId } = useParams();

  const { data: catalogConfig } = useGetCatalogConfigQuery({ customerId: customerId as string }, { skip: !customerId });

  const columns: ColumnsType<ProductCatalogItem> = [
    {
      dataIndex: 'productNumber',
      title: (
        <Tooltip title={'This is the unique identifier of the released product in D365.'}>
          <Text ellipsis strong>
            Product Number
          </Text>
        </Tooltip>
      ),
      key: 'productNumber',
      width: '30ch',
      fixed: true,
      render: onProductNumber ? (value, record): JSX.Element => <Link onClick={(): void => onProductNumber(record.id)}>{value}</Link> : undefined
    },
    {
      dataIndex: 'customerXRef',
      title: (
        <Tooltip
          title={
            "This is the alternateItemId from the customerXref app. It is the customer's unique identifier of the product in their ERP. This is a read only value, and any changes in customerXref will be syncrhonized here."
          }>
          <Text ellipsis strong>
            {xRefLabel || 'Alternate Item Id'}
          </Text>
        </Tooltip>
      ),
      key: 'customerXRef',
      width: '15ch',
      render: (value, record): JSX.Element => (
        <Link
          target="_blank"
          disabled={!record.customerXRef}
          href={`${process.env.REACT_APP_XREF_REDIRECT_URL}/division/${customerId}?alternateItemIdContains=${record.customerXRef?.alternateItemId}`}>
          {record.customerXRef?.alternateItemId || ''}
        </Link>
      )
    },
    {
      dataIndex: 'defaultConfigType',
      title: (
        <Tooltip title={'This is a placeholder and is currently not being used.  It is related to product configuration in D365.'}>
          <Text ellipsis strong>
            Config Type
          </Text>
        </Tooltip>
      ),
      key: 'defaultConfigType',
      width: '11ch'
    },
    {
      dataIndex: 'isActive',
      title: (
        <Tooltip title={'This indicates wheter or not the record is active and in use by other systems.'}>
          <Text ellipsis strong>
            Active
          </Text>
        </Tooltip>
      ),
      key: 'isActive',
      width: '7ch',
      render: (value: boolean): string => (value ? 'TRUE' : 'FALSE')
    },
    {
      dataIndex: 'deploymentType',
      title: (
        <Tooltip title={'Determines whether or not the product catalog item is used in deployment requests, sparing requests, or both.'}>
          <Text ellipsis strong>
            Deployment Type
          </Text>
        </Tooltip>
      ),
      width: '16ch',
      key: 'deploymentType'
    },

    {
      dataIndex: 'isCommonStock',
      title: (
        <Tooltip
          title={
            'Indicates whether or not the item is common stock for the customer. This is used when calculating overage dimensions to be used for backfilled sales orders. When this is set to true, overage amounts will be fulfilled by the common stock default dimension fulfimment rules of the deployment request type.'
          }>
          <Text ellipsis strong>
            Common Stock
          </Text>
        </Tooltip>
      ),
      key: 'isCommonStock',
      width: '14ch',
      render: (value: boolean): string => (value ? 'TRUE' : 'FALSE')
    },
    {
      dataIndex: 'defaultOverageOwner',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.'}>
          <Text ellipsis strong>
            Overage Owner
          </Text>
        </Tooltip>
      ),
      key: 'defaultOverageOwner'
    },
    {
      dataIndex: 'defaultOverageCondition',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.'}>
          <Text ellipsis strong>
            Overage Condition
          </Text>
        </Tooltip>
      ),
      key: 'defaultOverageCondition'
    },
    {
      dataIndex: 'defaultOverageDisposition',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for orders with overage amounts. It is currently not being used.'}>
          <Text ellipsis strong>
            Overage Disposition
          </Text>
        </Tooltip>
      ),
      key: 'defaultOverageDisposition'
    },
    {
      dataIndex: 'salesNewPrice',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for product sales for program customers. It is currently not being used.'}>
          <Text ellipsis strong>
            New Price
          </Text>
        </Tooltip>
      ),
      key: 'salesNewPrice',
      width: '15ch',
      align: 'right',
      render: formatCurrency
    },
    {
      dataIndex: 'salesCertReconPrice',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for product sales for program customers. It is currently not being used.'}>
          <Text ellipsis strong>
            Cert Price
          </Text>
        </Tooltip>
      ),
      key: 'salesCertRecordPrice',
      width: '15ch',
      align: 'right',
      render: formatCurrency
    },
    {
      dataIndex: 'salesUsedPrice',
      title: (
        <Tooltip title={'This is a placeholder and is intended to be used for product sales for program customers. It is currently not being used.'}>
          <Text ellipsis strong>
            Used Price
          </Text>
        </Tooltip>
      ),
      key: 'salesUsedPrice',
      width: '15ch',
      align: 'right',
      render: formatCurrency
    }
  ];

  const isOverriddenConfig: ColumnType<ProductCatalogItem> = {
    dataIndex: 'isOverridden',
    title: (
      <Tooltip
        title={
          'Indicates whether or not the product catalog item has been set at the division level and is overriding the business product catalog item. This can only be true if the inheritance level is set to Division or BusinessWithDivisionOverride.'
        }>
        <Text ellipsis strong>
          Is Overridden
        </Text>
      </Tooltip>
    ),
    key: 'isOverridden',
    width: '10ch',
    render: (value, record): JSX.Element | undefined => (pathname.includes('division') ? <OverridenSwitch catalogItem={record} /> : undefined)
  };

  if (pathname.includes('division') && catalogConfig?.dataInheritanceLevel !== 'Business' && catalogConfig?.dataInheritanceLevel !== 'Division') columns.splice(1, 0, isOverriddenConfig);

  return <Table columns={columns} size="small" rowKey={(item): string => item.productNumber} {...rest} />;
};
