import { RecoverRulesContextMenu } from 'components/atoms/RecoverRulesContextMenu';
import { AssignedRulesEditModal } from 'components/molecules/AssignedRulesEditModal';
import { AssignedRulesControls } from 'components/organisms/AssignedRulesControls';
import { RulesTable } from 'components/organisms/RulesTable';
import { ProductCatalogItem } from 'models/ProductCatalog';
import React, { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDeletedProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const RecoverRulesPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: customerId } = useParams();
  const dispatch = useAppDispatch();

  const { juliaProductCatalogParams, assignedRules } = useAppSelector((state) => state);

  const { data: deletedProducts, isLoading } = useGetDeletedProductCatalogItemsQuery({ customerId: customerId as string, params: juliaProductCatalogParams }, { skip: !customerId });

  /* ******************** Variables / Functions ******************** */
  const handleSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductCatalogItem[]): void => {
    dispatch(setSelectedRules(selectedRows.map((item) => item.id)));
  };

  useEffect(() => (): any => dispatch(setSelectedRules([])), []);

  return (
    <>
      <AssignedRulesControls />
      <RecoverRulesContextMenu>
        <RulesTable
          loading={isLoading}
          dataSource={deletedProducts?.data}
          rowSelection={{ type: 'checkbox', onChange: handleSelectRow, selectedRowKeys: assignedRules.selectedRules }}
          onRow={(record): { onContextMenu: (event: MouseEvent) => void } => ({
            onContextMenu: (event): void => {
              event.preventDefault();
              dispatch(setSelectedRules([record.id]));
            }
          })}
          size="small"
          rowKey={(item): string => item.id}
          pagination={{ total: deletedProducts?.totalCount, defaultPageSize: 25 }}
        />
      </RecoverRulesContextMenu>
      <AssignedRulesEditModal />
    </>
  );
};
