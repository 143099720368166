import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteProductCatalogItemMutation } from 'redux/services/julia/juliaApi';
import { useGetCatalogConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppSelector } from 'redux/store';

export const AssignedRulesContextMenu: React.FC = ({ children }) => {
  /* ******************** Hooks ******************** */
  const { id: customerId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [_, setSearchParams] = useSearchParams();
  const { selectedRules } = useAppSelector((state) => state.assignedRules);

  /* ****************** API hooks ****************** */
  const { data: divisionCatalogConfig } = useGetCatalogConfigQuery({ customerId: customerId as string }, { skip: !customerId });
  const [deleteCatalogItem] = useDeleteProductCatalogItemMutation();

  /* ******************** Functions / Variables ******************** */
  const isBusinessOnly = divisionCatalogConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');

  const handleEditRecords = (): void => {
    setSearchParams({ productIds: selectedRules.join(',') });
  };

  const handleDeleteItems = async (): Promise<void> => {
    if (!customerId) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const productId of selectedRules) {
      try {
        deleteCatalogItem({ customerId: customerId, productId }).unwrap();
      } catch (error) {
        message.error(`Failed to delete product catalog item ${productId}`);
      }
    }

    message.success(`Deleted product catalog item(s)!`);
    dispatch(setSelectedRules([]));
  };

  const menu = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleEditRecords} disabled={!selectedRules.length}>
        Edit
      </Menu.Item>
      <Menu.Item key="2" danger={!!selectedRules.length} onClick={handleDeleteItems} icon={<DeleteOutlined />} disabled={!selectedRules.length}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']} disabled={isBusinessOnly}>
      <div className="site-dropdown-context-menu">{children}</div>
    </Dropdown>
  );
};
