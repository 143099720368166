import { Col, Row } from 'antd';
import {
  ConditionsFilter,
  DefaultConfigTypeFilter,
  DeploymentTypeFilter,
  DispositionsFilter,
  IsActiveFilter,
  IsCommonStockFilter,
  IsOverriddenFilter,
  OwnersFilter,
  ProductNumberFilter
} from 'components/atoms/RulesFilters';
import { AlternateItemIdFilter } from 'components/atoms/RulesFilters/AlternateItemIdFilter';
import { IsDimPermissionsOverageFilter } from 'components/atoms/RulesFilters/IsDimPermissionsOverageFilter';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetCatalogConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

export const RulesFilters: React.FC = () => {
  const { id: customerId } = useParams();
  const { pathname } = useLocation();

  const { data: catalogConfig } = useGetCatalogConfigQuery({ customerId: customerId as string }, { skip: !customerId });

  return (
    <Row gutter={[8, 18]}>
      <Col>
        <ProductNumberFilter />
      </Col>
      {pathname.includes('division') && (
        <Col>
          <AlternateItemIdFilter />
        </Col>
      )}
      <Col>
        <DefaultConfigTypeFilter />
      </Col>
      <Col>
        <DeploymentTypeFilter />
      </Col>
      <Col>
        <IsActiveFilter />
      </Col>
      <Col>
        <IsDimPermissionsOverageFilter />
      </Col>
      <Col>
        <IsCommonStockFilter />
      </Col>
      {catalogConfig?.dataInheritanceLevel === 'BusinessWithDivisionOverride' && (
        <Col>
          <IsOverriddenFilter />
        </Col>
      )}
      <Col>
        <OwnersFilter />
      </Col>
      <Col>
        <ConditionsFilter />
      </Col>
      <Col>
        <DispositionsFilter />
      </Col>
    </Row>
  );
};
